import breeam1 from '../../../static/images/icons/breeam.png'
import breeam2 from '../../../static/images/icons/breeam.png'
import gear from '../../../static/images/icons/gear.png'
import platinum from '../../../static/images/icons/leed_platinum.png'
import gold from '../../../static/images/icons/leed_gold.png'
import silver from '../../../static/images/icons/leed_silver.png'
import tucuman from "../../../static/images/madrid/tucuman.jpg"
import mendezAlvaro from "../../../static/images/madrid/mendez_alvaro.jpg"
import miguelAngel from "../../../static/images/madrid/miguel_angel_23.jpg"
import castellana163 from "../../../static/images/madrid/castellana_163.jpg"
import recoletos37 from "../../../static/images/madrid/recoletos_37.jpg"
import genova17 from "../../../static/images/madrid/genova_17.jpg"
import castellana52 from "../../../static/images/madrid/castellana_52.jpg"
import serrano73 from "../../../static/images/madrid/serrano_73.jpg"
import castellana43 from "../../../static/images/madrid/castellana_43.jpg"
import miguelAngel11 from "../../../static/images/madrid/miguel_angel_11.jpg"
import almagro9 from "../../../static/images/madrid/almagro_9.jpg"
import sagasta27 from "../../../static/images/madrid/sagasta_27.jpg"
import sagasta31 from "../../../static/images/madrid/sagasta_31-33.jpg"
import abascal56 from "../../../static/images/madrid/jose_abascal_56.jpg"
import abascal45 from "../../../static/images/madrid/jose_abascal_45.jpg"
import santaEngracia from "../../../static/images/madrid/santa_engracia.jpg"
import velazquez86 from "../../../static/images/madrid/velazquez_86d.jpg"
import ramonCruz84 from "../../../static/images/madrid/ramon_cruz_84.jpg"
import discovery from "../../../static/images/madrid/discovery_building.jpg"
import joanMaragall from "../../../static/images/madrid/joan_maragall_53.jpg"
import manuelFalla from "../../../static/images/madrid/manuel_de_falla_7.jpg"
import arturoSoria336 from "../../../static/images/madrid/arturo_soria_336.jpg"
import lopezHoyos from "../../../static/images/madrid/lopez_hoyos_35.jpg"
import franciscoSivela from "../../../static/images/madrid/francisco_silvela_42.jpg"
import window from "../../../static/images/madrid/the_window_building.jpg"
import santaHortensia from "../../../static/images/madrid/santa_hortensia_26-28.jpg"
import ramirez_arellano_37 from "../../../static/images/madrid/ramirez_arellano_37.jpg"
import MV49 from "../../../static/images/madrid/MV49.jpg"
import Ortega_Gasset_100 from "../../../static/images/madrid/ortega_gasset_100.jpg"
import egeo from "../../../static/images/madrid/egeo.jpg"
import alfonso_XII_62 from "../../../static/images/madrid/alfonso_XII_62.jpg"
import Puerto_Somport_8 from "../../../static/images/madrid/puerto_somport_8.jpg"
import puerto_somport_10 from "../../../static/images/madrid/puerto_somport_10-18.jpg"
import cedro from "../../../static/images/madrid/cedro.jpg"
import oblicua from "../../../static/images/madrid/oblicua.jpg"
import Ribera_Loira_28 from "../../../static/images/madrid/ribera_loira_28.jpg"
import Ramirez_Arellano_15 from "../../../static/images/madrid/ramirez_arellano_15.jpg"
import Josefa_Valcarcel_24 from "../../../static/images/madrid/josefa_valcarcel_24.jpg"
import Josefa_Valcarcel_40 from "../../../static/images/madrid/josefa_valcarcel_40.jpg"
import JILT7 from "../../../static/images/madrid/JILT7.jpg"
import alcala_506 from "../../../static/images/madrid/alcala_506.jpg"

const madridData = [
    {
        id: "1",
        name: "Tucumán",
        address: "Glorieta Mar Caribe, 1",
        img_path: "madrid/tucuman.jpg",
        img: tucuman,
        new: "Nuevo Proyecto",
        active: "Tucumán",
        bream1: "Bream Parte I: Good", 
        bream2: "Bream Parte II: Good",
        bream3: "",
        hidden: "",
        hidden2: "invisible",
        imgbream1: breeam1,
        imgbream2: breeam2,
        position: { lat: 40.45529648843062, lng: -3.6223342940379073 },
          bimCoordination: "https://docs.b360.autodesk.com/projects/af39d16c-18c7-4237-9380-91b6ccf3beef/folders/urn:adsk.wipprod:fs.folder:co.9-EcK-RhTsSSzrCWDNFYig/detail/viewer/items/urn:adsk.wipprod:dm.lineage:zJKYkm7SSvKp80fmDBdcyw",
          bimArchitecture: "https://docs.b360.autodesk.com/projects/af39d16c-18c7-4237-9380-91b6ccf3beef/folders/urn:adsk.wipprod:fs.folder:co.GBoRorPGSvOJ44hdOmVlVg/detail/viewer/items/urn:adsk.wipprod:dm.lineage:BxDoOKTfRO2Yznbw85I97g",
          bimInstallations: "https://docs.b360.autodesk.com/projects/af39d16c-18c7-4237-9380-91b6ccf3beef/folders/urn:adsk.wipprod:fs.folder:co.nXNnTEIPQoyt4iE1W-ELGg/detail/viewer/items/urn:adsk.wipprod:dm.lineage:ucmxj-G4SSeXjDjWnT6zdA",
          bimMepMechanics: 'https://docs.b360.autodesk.com/projects/af39d16c-18c7-4237-9380-91b6ccf3beef/folders/urn:adsk.wipprod:fs.folder:co.RCOaL5FzQNSmrijjCnoQZg/detail/viewer/items/urn:adsk.wipprod:dm.lineage:33zeNIA5Qsa_frufYmJZfQ',
          bimMepPlumbing: '#',
          bimMepElectricity: 'https://docs.b360.autodesk.com/projects/af39d16c-18c7-4237-9380-91b6ccf3beef/folders/urn:adsk.wipprod:fs.folder:co.nXNnTEIPQoyt4iE1W-ELGg/detail/viewer/items/urn:adsk.wipprod:dm.lineage:Dv2UbsXWTb69eLEw3bTITg',
          bimMepPci: 'https://docs.b360.autodesk.com/projects/af39d16c-18c7-4237-9380-91b6ccf3beef/folders/urn:adsk.wipprod:fs.folder:co.nXNnTEIPQoyt4iE1W-ELGg/detail/viewer/items/urn:adsk.wipprod:dm.lineage:SgsuSjbWSFqZ1TvbS6yy_Q',
          bimMepBms: '#',
          bimImplantations: '#',
          bimCad: 'https://docs.b360.autodesk.com/projects/af39d16c-18c7-4237-9380-91b6ccf3beef/folders/urn:adsk.wipprod:fs.folder:co.vc5-TvVFRuGPiLh7JS4_rg/detail/viewer/items/urn:adsk.wipprod:dm.lineage:rC7fPh40TCOjEVZOX3AHZA',
          docWorkRecords: '#',
          docWorkCertificate: '',
          docMaintenanceCertificate: '#',
          docGeotechnicalControl: '#',
          docControlInstallations: '#',
          docActivitiesCoordination: '#',
          docCorrectives: '#',
          docGeneral: '#',
          docAdministrativeLegal: '#',
          docRSC: '#',
          docPrevious: '#',
          docRisksEvaluation: '#',
          docISOs: '#',
          docImplementations: '#',
          docOCTReports: '#',
          docWorkReports:'#',
          docTechnicalReports: '#',
          docBuildingBook:'#',
          docLicenses: '#',
          docBiddingWorkContracts: '#',
          docSelfprotectionManuals: '#',
          docProjects:'#',
          docProcessingLegalization: '#',
          docFlats: '#',
          commercialFlats: '#',
          commercialSurfaces: '#',
          commercialProjectReport: '#',
          commercialCurrentPhase: '#',
          commercialRenders: '',
          commercialAR: 'https://twinmotion.unrealengine.com/presentation/fdl6jYfFQtuEyrSo',
          stockStocks: '#',
          ecoCarbonFootprint: "#",
          ecoCertificate: "#",
          ecoProvisioning: "#",
          ecoEnergyEfficiency: "#",
          ecoWasteManagement: "#", 
          ecoLifeCycle: "#",
          areaAeo: "https://docs.b360.autodesk.com/projects/af39d16c-18c7-4237-9380-91b6ccf3beef/folders/urn:adsk.wipprod:fs.folder:co.0PLQ_IptTbeUEr2OPNesqA/detail/viewer/items/urn:adsk.wipprod:dm.lineage:SNbH0gipSBmtVTDe6zF88w",
          areaTableAeo: "https://docs.b360.autodesk.com/projects/af39d16c-18c7-4237-9380-91b6ccf3beef/folders/urn:adsk.wipprod:fs.folder:co.ITe_P0f0R9iew6VB-uV2vw/detail/viewer/items/urn:adsk.wipprod:dm.lineage:AHWjZO3qRx62gyfp6y1_Og",
          areaSurfaces: 'https://docs.b360.autodesk.com/projects/af39d16c-18c7-4237-9380-91b6ccf3beef/folders/urn:adsk.wipprod:fs.folder:co.ITe_P0f0R9iew6VB-uV2vw/detail/viewer/items/urn:adsk.wipprod:dm.lineage:37WSpZHTT3-ai5N3-frUBQ',
          tracingContract: "#",
          tracingCost: 'https://docs.b360.autodesk.com/projects/af39d16c-18c7-4237-9380-91b6ccf3beef/folders/urn:adsk.wipprod:fs.folder:co.sZw-sy9vQGmC6mZGb_xeXw/detail/viewer/items/urn:adsk.wipprod:dm.lineage:EyXssWBAQUObrCslqYOKkQ',
          tracingTerm: 'https://docs.b360.autodesk.com/projects/af39d16c-18c7-4237-9380-91b6ccf3beef/folders/urn:adsk.wipprod:fs.folder:co.n2SfUfKqTHeAK9zDL-7uKw/detail/viewer/items/urn:adsk.wipprod:dm.lineage:Jj0_8j_bTamLcvT8cW8txA',
          tracingBudgets: 'https://insight.b360.autodesk.com/accounts/58116bfa-8bd1-40e9-b835-d78c0f1fad0e/projects/af39d16c-18c7-4237-9380-91b6ccf3beef/schedule',
          tracingCurrentPhase: 'https://docs.b360.autodesk.com/projects/af39d16c-18c7-4237-9380-91b6ccf3beef/folders/urn:adsk.wipprod:fs.folder:co.mrrbgSdgS4SigSM8qMLtNA/detail/viewer/items/urn:adsk.wipprod:dm.lineage:_FeTrs7DR_iho6ModAceRQ',
          tracingScan: "https://diseno9.viewin360.co/share/collection/7FkM9?logo=1&card=1&info=0&logosize=200&fs=1&vr=1&zoom=1&initload=0&thumbs=1",
          buildingMemoryQuality: '#',
          buildingCFO: '#',
          buildingWorkRecords: '#',
          buildingCadastralReference: '#',
          buildingLicense: '#',
          buildingASBuilt: '#',
          buildingWellnessCertificate: '#',
          buildingPECOC: '#',
          buildingLegalizations: '#',
          buildingCompaniesInvolved: '#',
          buildingBuildingBook: '#',
          buildingPlanning: '#',
          buildingFlats: '#',
      },
      {
        id: "2",
        name: "Campus M.Álvaro",
        address: "Calle Méndez Álvaro, 61",
        img_path: "madrid/mendez_alvaro.jpg",
        img: mendezAlvaro,
        new: "Nuevo Proyecto",
        active: "Campus M.Álvaro",
        bream1: "",
        bream2: "",
        bream3: "",
        hidden: "invisible",
        hidden2: "invisible",
        hidden3: "invisible",
        position: { lat: 40.39767798034265, lng: -3.6819600056947186 },
          bimCoordination: '#',
          bimArchitecture: 'https://docs.b360.autodesk.com/projects/a812e34e-360c-49ea-9170-3d65a1d062d9/folders/urn:adsk.wipprod:fs.folder:co.oqaZYxD2RPmuUEXC6r5yGA/detail/viewer/items/urn:adsk.wipprod:dm.lineage:Hu4Owg5FRq6XykC8Rf2eNw',
          bimInstallations: '#',
          bimMepMechanics: 'https://docs.b360.autodesk.com/projects/a812e34e-360c-49ea-9170-3d65a1d062d9/folders/urn:adsk.wipprod:fs.folder:co.Fl19T20vSOq0HlcWkMZUww/detail/viewer/items/urn:adsk.wipprod:dm.lineage:sB7IwBpYTWKMSnYfxBR3_w',
          bimMepPlumbing: 'https://docs.b360.autodesk.com/projects/a812e34e-360c-49ea-9170-3d65a1d062d9/folders/urn:adsk.wipprod:fs.folder:co.Fl19T20vSOq0HlcWkMZUww/detail/viewer/items/urn:adsk.wipprod:dm.lineage:zt8g7KfwSJel_emsBk-dxg',
          bimMepElectricity: 'https://docs.b360.autodesk.com/projects/a812e34e-360c-49ea-9170-3d65a1d062d9/folders/urn:adsk.wipprod:fs.folder:co.Fl19T20vSOq0HlcWkMZUww/detail/viewer/items/urn:adsk.wipprod:dm.lineage:hVgPPeKdTU-dqmPqb4o_fQ',
          bimMepPci: 'https://docs.b360.autodesk.com/projects/a812e34e-360c-49ea-9170-3d65a1d062d9/folders/urn:adsk.wipprod:fs.folder:co.Fl19T20vSOq0HlcWkMZUww/detail/viewer/items/urn:adsk.wipprod:dm.lineage:5w2AcLrGTq-YOQ5iesSqww',
          bimMepBms: '#',
          bimImplantations: '#',
          bimCad: 'https://docs.b360.autodesk.com/projects/a812e34e-360c-49ea-9170-3d65a1d062d9/folders/urn:adsk.wipprod:fs.folder:co.XpULg2FvRn-q97KhrpZLSw/detail/viewer/items/urn:adsk.wipprod:dm.lineage:twROmvGmQWquLm17wH6f6Q',
          docWorkRecords: '#',
          docWorkCertificate: '#',
          docMaintenanceCertificate: '#',
          docGeotechnicalControl: '#',
          docControlInstallations: '#',
          docActivitiesCoordination: '#',
          docCorrectives: '#',
          docGeneral: '#',
          docAdministrativeLegal: '#',
          docRSC: '#',
          docPrevious: '#',
          docRisksEvaluation: '#',
          docISOs: '#',
          docImplementations: '#',
          docOCTReports: '#',
          docWorkReports:'#',
          docTechnicalReports: '#',
          docBuildingBook:'#',
          docLicenses: '#',
          docBiddingWorkContracts: '#',
          docSelfprotectionManuals: '#',
          docProjects:'#',
          docProcessingLegalization: '#',
          docFlats: '#',
          commercialFlats: '#',
          commercialSurfaces: '#',
          commercialProjectReport: '#',
          commercialCurrentPhase: '#',
          commercialRenders: '#',
          commercialAR: '#',
          stockStocks: '#',
          ecoCarbonFootprint: "#",
          ecoCertificate: "#",
          ecoProvisioning: "#",
          ecoEnergyEfficiency: "#",
          ecoWasteManagement: "#",
          ecoLifeCycle: "#",
          areaAeo: "#",
          areaTableAeo: "#",
          areaSurfaces: '#',
          tracingContract: "#",
          tracingCost: '#',
          tracingTerm: '#',
          tracingBudgets: '#',
          tracingCurrentPhase: '#',
          tracingScan: "https://aec.cintoo.com/09153D3C141E273F3618",
          buildingMemoryQuality: '#',
          buildingCFO: '#',
          buildingWorkRecords: '#',
          buildingCadastralReference: '#',
          buildingLicense: '#',
          buildingASBuilt: '#',
          buildingWellnessCertificate: '#',
          buildingPECOC: '#',
          buildingLegalizations: '#',
          buildingCompaniesInvolved: '#',
          buildingBuildingBook: '#',
          buildingPlanning: '#',
          buildingFlats: '#',
      },
      {
        id: "3",
        name: "Miguel Ángel 23",
        address: "Calle Miguel Ángel, 23",
        img_path: "madrid/miguel_angel_23.jpg",
        img: miguelAngel,
        bream1: "Certificado en trámite",
        bream2: "",
        hidden: "invisible",
        hidden2: "invisible",
        imgbream1: gear,
        position: { lat: 40.43652517341069, lng: -3.691382133323277 },
          bimCoordination: '#',
          bimArchitecture: '#',
          bimInstallations: '#',
          bimMepMechanics: '#',
          bimMepPlumbing: '#',
          bimMepElectricity: '#',
          bimMepPci: '#',
          bimMepBms: '#',
          bimImplantations: '#',
          bimCad: '#',
          docWorkRecords: '#',
          docWorkCertificate: '#',
          docMaintenanceCertificate: '#',
          docGeotechnicalControl: '#',
          docControlInstallations: '#',
          docActivitiesCoordination: '#',
          docCorrectives: '#',
          docGeneral: '#',
          docAdministrativeLegal: '#',
          docRSC: '#',
          docPrevious: '#',
          docRisksEvaluation: '#',
          docISOs: '#',
          docImplementations: '#',
          docOCTReports: '#',
          docWorkReports:'#',
          docTechnicalReports: '#',
          docBuildingBook:'#',
          docLicenses: '#',
          docBiddingWorkContracts: '#',
          docSelfprotectionManuals: '#',
          docProjects:'#',
          docProcessingLegalization: '#',
          docFlats: '#',
          commercialFlats: '#',
          commercialSurfaces: '#',
          commercialProjectReport: '#',
          commercialCurrentPhase: '#',
          commercialRenders: '#',
          commercialAR: '#',
          stockStocks: '#',
          ecoCarbonFootprint: "#",
          ecoCertificate: "#",
          ecoProvisioning: "#",
          ecoEnergyEfficiency: "#",
          ecoWasteManagement: "#",
          ecoLifeCycle: "#",
          areaAeo: "#",
          areaTableAeo: "#",
          areaSurfaces: '#',
          tracingContract: "#",
          tracingCost: '#',
          tracingTerm: '#',
          tracingBudgets: '#',
          tracingCurrentPhase: '#',
          tracingScan: "#",
          buildingMemoryQuality: '#',
          buildingCFO: '#',
          buildingWorkRecords: '#',
          buildingCadastralReference: '#',
          buildingLicense: '#',
          buildingASBuilt: '#',
          buildingWellnessCertificate: '#',
          buildingPECOC: '#',
          buildingLegalizations: '#',
          buildingCompaniesInvolved: '#',
          buildingBuildingBook: '#',
          buildingPlanning: '#',
          buildingFlats: '#',
      },
      {
        id: "4",
        name: "Castellana 163",
        address: "Paseo Castellana, 163",
        img_path: "madrid/castellana_163.jpg",
        img: castellana163,
        bream1: "Certificado en trámite",
        bream2: "",
        bream3: "",
        hidden: "invisible",
        hidden2: "invisible",
        imgbream1: gear,
        imgbream2: breeam2,
        imgbream3: platinum,
        position: { lat: 40.46176713352714, lng: -3.6912562382960563 },
          bimCoordination: '#',
          bimArchitecture: '#',
          bimInstallations: '#',
          bimMepMechanics: '#',
          bimMepPlumbing: '#',
          bimMepElectricity: '#',
          bimMepPci: '#',
          bimMepBms: '#',
          bimImplantations: '#',
          bimCad: '#',
          docWorkRecords: '#',
          docWorkCertificate: '#',
          docMaintenanceCertificate: '#',
          docGeotechnicalControl: '#',
          docControlInstallations: '#',
          docActivitiesCoordination: '#',
          docCorrectives: '#',
          docGeneral: '#',
          docAdministrativeLegal: '#',
          docRSC: '#',
          docPrevious: '#',
          docRisksEvaluation: '#',
          docISOs: '#',
          docImplementations: '#',
          docOCTReports: '#',
          docWorkReports:'#',
          docTechnicalReports: '#',
          docBuildingBook:'#',
          docLicenses: '#',
          docBiddingWorkContracts: '#',
          docSelfprotectionManuals: '#',
          docProjects:'#',
          docProcessingLegalization: '#',
          docFlats: '#',
          commercialFlats: '#',
          commercialSurfaces: '#',
          commercialProjectReport: '#',
          commercialCurrentPhase: '#',
          commercialRenders: '#',
          commercialAR: '#',
          stockStocks: '#',
          ecoCarbonFootprint: "#",
          ecoCertificate: "#",
          ecoProvisioning: "#",
          ecoEnergyEfficiency: "#",
          ecoWasteManagement: "#",
          ecoLifeCycle: "#",
          areaAeo: "#",
          areaTableAeo: "#",
          areaSurfaces: '#',
          tracingContract: "#",
          tracingCost: '#',
          tracingTerm: '#',
          tracingBudgets: '#',
          tracingCurrentPhase: '#',
          tracingScan: "#",
          buildingMemoryQuality: '#',
          buildingCFO: '#',
          buildingWorkRecords: '#',
          buildingCadastralReference: '#',
          buildingLicense: '#',
          buildingASBuilt: '#',
          buildingWellnessCertificate: '#',
          buildingPECOC: '#',
          buildingLegalizations: '#',
          buildingCompaniesInvolved: '#',
          buildingBuildingBook: '#',
          buildingPlanning: '#',
          buildingFlats: '#',
      },
      {
        id: "5",
        name: "Recoletos 37",
        address: "Paseo de Recoletos 37",
        img_path: "madrid/recoletos_37.jpg",
        img: recoletos37,
        bream1: "Bream Parte I: Very Good",
        bream2: "Bream Parte II: Excellent",
        bream3: "",
        hidden: "",
        hidden2: "invisible",
        imgbream1: breeam1,
        imgbream2: breeam2,
        imgbream3: gold,
        position: { lat: 40.4247042438464, lng: -3.691452767983917 },
          bimCoordination: '#',
          bimArchitecture: '#',
          bimInstallations: '#',
          bimMepMechanics: '#',
          bimMepPlumbing: '#',
          bimMepElectricity: '#',
          bimMepPci: '#',
          bimMepBms: '#',
          bimImplantations: '#',
          bimCad: '#',
          docWorkRecords: '#',
          docWorkCertificate: '#',
          docMaintenanceCertificate: '#',
          docGeotechnicalControl: '#',
          docControlInstallations: '#',
          docActivitiesCoordination: '#',
          docCorrectives: '#',
          docGeneral: '#',
          docAdministrativeLegal: '#',
          docRSC: '#',
          docPrevious: '#',
          docRisksEvaluation: '#',
          docISOs: '#',
          docImplementations: '#',
          docOCTReports: '#',
          docWorkReports:'#',
          docTechnicalReports: '#',
          docBuildingBook:'#',
          docLicenses: '#',
          docBiddingWorkContracts: '#',
          docSelfprotectionManuals: '#',
          docProjects:'#',
          docProcessingLegalization: '#',
          docFlats: '#',
          commercialFlats: '#',
          commercialSurfaces: '#',
          commercialProjectReport: '#',
          commercialCurrentPhase: '#',
          commercialRenders: '#',
          commercialAR: '#',
          stockStocks: '#',
          ecoCarbonFootprint: "#",
          ecoCertificate: "#",
          ecoProvisioning: "#",
          ecoEnergyEfficiency: "#",
          ecoWasteManagement: "#",
          ecoLifeCycle: "#",
          areaAeo: "#",
          areaTableAeo: "#",
          areaSurfaces: '#',
          tracingContract: "#",
          tracingCost: '#',
          tracingTerm: '#',
          tracingBudgets: '#',
          tracingCurrentPhase: '#',
          tracingScan: "#",
          buildingMemoryQuality: '#',
          buildingCFO: '#',
          buildingWorkRecords: '#',
          buildingCadastralReference: '#',
          buildingLicense: '#',
          buildingASBuilt: '#',
          buildingWellnessCertificate: '#',
          buildingPECOC: '#',
          buildingLegalizations: '#',
          buildingCompaniesInvolved: '#',
          buildingBuildingBook: '#',
          buildingPlanning: '#',
          buildingFlats: '#',
      },
      {
        id: "6",
        name: "Génova 17",
        address: "Calle Génova, 17",
        img_path: "madrid/genova_17.jpg",
        img: genova17,
        bream1: "Bream Parte I: Very Good",
        bream2: "Bream Parte II: Excellent",
        bream3: "",
        hidden: "",
        hidden2: "invisible",
        imgbream1: breeam1,
        imgbream2: breeam2,
        imgbream3: gold,
        position: { lat: 40.42686896662603, lng: -3.693351893365671 },
        bimCoordination: '#',
        bimArchitecture: '#',
        bimInstallations: '#',
        bimMepMechanics: '#',
        bimMepPlumbing: '#',
        bimMepElectricity: '#',
        bimMepPci: '#',
        bimMepBms: '#',
        bimImplantations: '#',
        bimCad: '#',
        docWorkRecords: '#',
        docWorkCertificate: '#',
        docMaintenanceCertificate: '#',
        docGeotechnicalControl: '#',
        docControlInstallations: '#',
        docActivitiesCoordination: '#',
        docCorrectives: '#',
        docGeneral: '#',
        docAdministrativeLegal: '#',
        docRSC: '#',
        docPrevious: '#',
        docRisksEvaluation: '#',
        docISOs: '#',
        docImplementations: '#',
        docOCTReports: '#',
        docWorkReports:'#',
        docTechnicalReports: '#',
        docBuildingBook:'#',
        docLicenses: '#',
        docBiddingWorkContracts: '#',
        docSelfprotectionManuals: '#',
        docProjects:'#',
        docProcessingLegalization: '#',
        docFlats: '#',
        commercialFlats: '#',
        commercialSurfaces: '#',
        commercialProjectReport: '#',
        commercialCurrentPhase: '#',
        commercialRenders: '#',
        commercialAR: '#',
        stockStocks: '#',
        ecoCarbonFootprint: "#",
        ecoCertificate: "#",
        ecoProvisioning: "#",
        ecoEnergyEfficiency: "#",
        ecoWasteManagement: "#",
        ecoLifeCycle: "#",
        areaAeo: "#",
        areaTableAeo: "#",
        areaSurfaces: '#',
        tracingContract: "#",
        tracingCost: '#',
        tracingTerm: '#',
        tracingBudgets: '#',
        tracingCurrentPhase: '#',
        tracingScan: "#",
        buildingMemoryQuality: '#',
        buildingCFO: '#',
        buildingWorkRecords: '#',
        buildingCadastralReference: '#',
        buildingLicense: '#',
        buildingASBuilt: '#',
        buildingWellnessCertificate: '#',
        buildingPECOC: '#',
        buildingLegalizations: '#',
        buildingCompaniesInvolved: '#',
        buildingBuildingBook: '#',
        buildingPlanning: '#',
        buildingFlats: '#',
      },
      {
        id: "7",
        name: "Castellana 52",
        address: "Paseo Castellana, 52",
        img_path: "madrid/castellana_52.jpg",
        img: castellana52,
        bream1: "Bream Parte I: Very Good",
        bream2: "Bream Parte II: Excellent",
        bream3: "",
        hidden: "",
        hidden2: "invisible",
        imgbream1: breeam1,
        imgbream2: breeam2,
        imgbream3: gold,
        position: { lat: 40.442177893939586, lng: -3.686626503382135 },
          bimCoordination: '#',
          bimArchitecture: '#',
          bimInstallations: '#',
          bimMepMechanics: '#',
          bimMepPlumbing: '#',
          bimMepElectricity: '#',
          bimMepPci: '#',
          bimMepBms: '#',
          bimImplantations: '#',
          bimCad: '#',
          docWorkRecords: '#',
          docWorkCertificate: '#',
          docMaintenanceCertificate: '#',
          docGeotechnicalControl: '#',
          docControlInstallations: '#',
          docActivitiesCoordination: '#',
          docCorrectives: '#',
          docGeneral: '#',
          docAdministrativeLegal: '#',
          docRSC: '#',
          docPrevious: '#',
          docRisksEvaluation: '#',
          docISOs: '#',
          docImplementations: '#',
          docOCTReports: '#',
          docWorkReports:'#',
          docTechnicalReports: '#',
          docBuildingBook:'#',
          docLicenses: '#',
          docBiddingWorkContracts: '#',
          docSelfprotectionManuals: '#',
          docProjects:'#',
          docProcessingLegalization: '#',
          docFlats: '#',
          commercialFlats: '#',
          commercialSurfaces: '#',
          commercialProjectReport: '#',
          commercialCurrentPhase: '#',
          commercialRenders: '#',
          commercialAR: '#',
          stockStocks: '#',
          ecoCarbonFootprint: "#",
          ecoCertificate: "#",
          ecoProvisioning: "#",
          ecoEnergyEfficiency: "#",
          ecoWasteManagement: "#",
          ecoLifeCycle: "#",
          areaAeo: "#",
          areaTableAeo: "#",
          areaSurfaces: '#',
          tracingContract: "#",
          tracingCost: '#',
          tracingTerm: '#',
          tracingBudgets: '#',
          tracingCurrentPhase: '#',
          tracingScan: "#",
          buildingMemoryQuality: '#',
          buildingCFO: '#',
          buildingWorkRecords: '#',
          buildingCadastralReference: '#',
          buildingLicense: '#',
          buildingASBuilt: '#',
          buildingWellnessCertificate: '#',
          buildingPECOC: '#',
          buildingLegalizations: '#',
          buildingCompaniesInvolved: '#',
          buildingBuildingBook: '#',
          buildingPlanning: '#',
          buildingFlats: '#',
      },
      {
        id: "8",
        name: "Serrano 73",
        address: "Calle Serrano 73",
        img_path: "madrid/serrano_73.jpg",
        img: serrano73,
        bream1: "Bream Parte I: Good",
        bream2: "",
        bream3: "",
        hidden: "invisible",
        hidden2: "invisible",
        imgbream1: breeam1,
        imgbream2: gold,
        imgbream3: gold,
        position: { lat: 40.43430561614765, lng: -3.6868898226781304 },
          bimCoordination: '#',
          bimArchitecture: '#',
          bimInstallations: '#',
          bimMepMechanics: '#',
          bimMepPlumbing: '#',
          bimMepElectricity: '#',
          bimMepPci: '#',
          bimMepBms: '#',
          bimImplantations: '#',
          bimCad: '#',
          docWorkRecords: '#',
          docWorkCertificate: '#',
          docMaintenanceCertificate: '#',
          docGeotechnicalControl: '#',
          docControlInstallations: '#',
          docActivitiesCoordination: '#',
          docCorrectives: '#',
          docGeneral: '#',
          docAdministrativeLegal: '#',
          docRSC: '#',
          docPrevious: '#',
          docRisksEvaluation: '#',
          docISOs: '#',
          docImplementations: '#',
          docOCTReports: '#',
          docWorkReports:'#',
          docTechnicalReports: '#',
          docBuildingBook:'#',
          docLicenses: '#',
          docBiddingWorkContracts: '#',
          docSelfprotectionManuals: '#',
          docProjects:'#',
          docProcessingLegalization: '#',
          docFlats: '#',
          commercialFlats: '#',
          commercialSurfaces: '#',
          commercialProjectReport: '#',
          commercialCurrentPhase: '#',
          commercialRenders: '#',
          commercialAR: '#',
          stockStocks: '#',
          ecoCarbonFootprint: "#",
          ecoCertificate: "#",
          ecoProvisioning: "#",
          ecoEnergyEfficiency: "#",
          ecoWasteManagement: "#",
          ecoLifeCycle: "#",
          areaAeo: "#",
          areaTableAeo: "#",
          areaSurfaces: '#',
          tracingContract: "#",
          tracingCost: '#',
          tracingTerm: '#',
          tracingBudgets: '#',
          tracingCurrentPhase: '#',
          tracingScan: "#",
          buildingMemoryQuality: '#',
          buildingCFO: '#',
          buildingWorkRecords: '#',
          buildingCadastralReference: '#',
          buildingLicense: '#',
          buildingASBuilt: '#',
          buildingWellnessCertificate: '#',
          buildingPECOC: '#',
          buildingLegalizations: '#',
          buildingCompaniesInvolved: '#',
          buildingBuildingBook: '#',
          buildingPlanning: '#',
          buildingFlats: '#',
      },
    {
      id: "9",
      name: "Castellana 43",
      address: "Paseo Castellana, 43",
      img_path: "madrid/castellana_43.jpg",
      img: castellana43,
      bream1: "Bream Parte I: Very Good",
      bream2: "Leed Gold",
      bream3: "",
      hidden: "",
      hidden2: "invisible",
      imgbream1: breeam1,
      imgbream2: gold,
      imgbream3: gold,
      position: { lat: 40.43563251387372, lng: -3.6896435523832776 },
        bimCoordination: '#',
        bimArchitecture: '#',
        bimInstallations: '#',
        bimMepMechanics: '#',
        bimMepPlumbing: '#',
        bimMepElectricity: '#',
        bimMepPci: '#',
        bimMepBms: '#',
        bimImplantations: '#',
        bimCad: '#',
        docWorkRecords: '#',
        docWorkCertificate: '#',
        docMaintenanceCertificate: '#',
        docGeotechnicalControl: '#',
        docControlInstallations: '#',
        docActivitiesCoordination: '#',
        docCorrectives: '#',
        docGeneral: '#',
        docAdministrativeLegal: '#',
        docRSC: '#',
        docPrevious: '#',
        docRisksEvaluation: '#',
        docISOs: '#',
        docImplementations: '#',
        docOCTReports: '#',
        docWorkReports:'#',
        docTechnicalReports: '#',
        docBuildingBook:'#',
        docLicenses: '#',
        docBiddingWorkContracts: '#',
        docSelfprotectionManuals: '#',
        docProjects:'#',
        docProcessingLegalization: '#',
        docFlats: '#',
        commercialFlats: '#',
        commercialSurfaces: '#',
        commercialProjectReport: '#',
        commercialCurrentPhase: '#',
        commercialRenders: '#',
        commercialAR: '#',
        stockStocks: '#',
        ecoCarbonFootprint: "#",
        ecoCertificate: "#",
        ecoProvisioning: "#",
        ecoEnergyEfficiency: "#",
        ecoWasteManagement: "#",
        ecoLifeCycle: "#",
        areaAeo: "#",
        areaTableAeo: "#",
        areaSurfaces: '#',
        tracingContract: "#",
        tracingCost: '#',
        tracingTerm: '#',
        tracingBudgets: '#',
        tracingCurrentPhase: '#',
        tracingScan: "#",
        buildingMemoryQuality: '#',
        buildingCFO: '#',
        buildingWorkRecords: '#',
        buildingCadastralReference: '#',
        buildingLicense: '#',
        buildingASBuilt: '#',
        buildingWellnessCertificate: '#',
        buildingPECOC: '#',
        buildingLegalizations: '#',
        buildingCompaniesInvolved: '#',
        buildingBuildingBook: '#',
        buildingPlanning: '#',
        buildingFlats: '#',
    },
    {
      id: "10",
      name: "Miguel Ángel 11",
      address: "Calle Miguel Ángel, 11",
      img_path: "madrid/miguel_angel_11.jpg",
      img: miguelAngel11,
      bream1: "Bream Parte I: Very Good",
      bream2: "Bream Parte II: Excellent",
      bream3: "",
      hidden: "",
      hidden2: "invisible",
      imgbream1: breeam1,
      imgbream2: breeam2,
      imgbream3: gold,
      position: { lat: 40.43567027714221, lng: -3.691441777871716 },
        bimCoordination: '#',
        bimArchitecture: '#',
        bimInstallations: '#',
        bimMepMechanics: '#',
        bimMepPlumbing: '#',
        bimMepElectricity: '#',
        bimMepPci: '#',
        bimMepBms: '#',
        bimImplantations: '#',
        bimCad: '#',
        docWorkRecords: '#',
        docWorkCertificate: '#',
        docMaintenanceCertificate: '#',
        docGeotechnicalControl: '#',
        docControlInstallations: '#',
        docActivitiesCoordination: '#',
        docCorrectives: '#',
        docGeneral: '#',
        docAdministrativeLegal: '#',
        docRSC: '#',
        docPrevious: '#',
        docRisksEvaluation: '#',
        docISOs: '#',
        docImplementations: '#',
        docOCTReports: '#',
        docWorkReports:'#',
        docTechnicalReports: '#',
        docBuildingBook:'#',
        docLicenses: '#',
        docBiddingWorkContracts: '#',
        docSelfprotectionManuals: '#',
        docProjects:'#',
        docProcessingLegalization: '#',
        docFlats: '#',
        commercialFlats: '#',
        commercialSurfaces: '#',
        commercialProjectReport: '#',
        commercialCurrentPhase: '#',
        commercialRenders: '#',
        commercialAR: '#',
        stockStocks: '#',
        ecoCarbonFootprint: "#",
        ecoCertificate: "#",
        ecoProvisioning: "#",
        ecoEnergyEfficiency: "#",
        ecoWasteManagement: "#",
        ecoLifeCycle: "#",
        areaAeo: "#",
        areaTableAeo: "#",
        areaSurfaces: '#',
        tracingContract: "#",
        tracingCost: '#',
        tracingTerm: '#',
        tracingBudgets: '#',
        tracingCurrentPhase: '#',
        tracingScan: "#",
        buildingMemoryQuality: '#',
        buildingCFO: '#',
        buildingWorkRecords: '#',
        buildingCadastralReference: '#',
        buildingLicense: '#',
        buildingASBuilt: '#',
        buildingWellnessCertificate: '#',
        buildingPECOC: '#',
        buildingLegalizations: '#',
        buildingCompaniesInvolved: '#',
        buildingBuildingBook: '#',
        buildingPlanning: '#',
        buildingFlats: '#',
    },
    {
      id: "11",
      name: "Almagro 9",
      address: "Calle Almagro, 9",
      img_path: "madrid/almagro_9.jpg",
      img: almagro9,
      bream1: "Bream Parte I: Good",
      bream2: "Bream Parte II: Very Good",
      bream3: "Leed Gold",
      hidden: "",
      hidden2: "",
      imgbream1: breeam1,
      imgbream2: breeam2,
      imgbream3: gold,
      position: { lat: 40.42997236304845, lng: -3.69370160965705 },
        bimCoordination: '#',
        bimArchitecture: '#',
        bimInstallations: '#',
        bimMepMechanics: '#',
        bimMepPlumbing: '#',
        bimMepElectricity: '#',
        bimMepPci: '#',
        bimMepBms: '#',
        bimImplantations: '#',
        bimCad: '#',
        docWorkRecords: '#',
        docWorkCertificate: '#',
        docMaintenanceCertificate: '#',
        docGeotechnicalControl: '#',
        docControlInstallations: '#',
        docActivitiesCoordination: '#',
        docCorrectives: '#',
        docGeneral: '#',
        docAdministrativeLegal: '#',
        docRSC: '#',
        docPrevious: '#',
        docRisksEvaluation: '#',
        docISOs: '#',
        docImplementations: '#',
        docOCTReports: '#',
        docWorkReports:'#',
        docTechnicalReports: '#',
        docBuildingBook:'#',
        docLicenses: '#',
        docBiddingWorkContracts: '#',
        docSelfprotectionManuals: '#',
        docProjects:'#',
        docProcessingLegalization: '#',
        docFlats: '#',
        commercialFlats: '#',
        commercialSurfaces: '#',
        commercialProjectReport: '#',
        commercialCurrentPhase: '#',
        commercialRenders: '#',
        commercialAR: '#',
        stockStocks: '#',
        ecoCarbonFootprint: "#",
        ecoCertificate: "#",
        ecoProvisioning: "#",
        ecoEnergyEfficiency: "#",
        ecoWasteManagement: "#",
        ecoLifeCycle: "#",
        areaAeo: "#",
        areaTableAeo: "#",
        areaSurfaces: '#',
        tracingContract: "#",
        tracingCost: '#',
        tracingTerm: '#',
        tracingBudgets: '#',
        tracingCurrentPhase: '#',
        tracingScan: "#",
        buildingMemoryQuality: '#',
        buildingCFO: '#',
        buildingWorkRecords: '#',
        buildingCadastralReference: '#',
        buildingLicense: '#',
        buildingASBuilt: '#',
        buildingWellnessCertificate: '#',
        buildingPECOC: '#',
        buildingLegalizations: '#',
        buildingCompaniesInvolved: '#',
        buildingBuildingBook: '#',
        buildingPlanning: '#',
        buildingFlats: '#',
    },
    {
      id: "12",
      name: "Sagasta 27",
      address: "Calle Sagasta, 27",
      img_path: "madrid/sagasta_27.jpg",
      img: sagasta27,
      bream1: "",
      bream2: "",
      bream3: "",
      hidden: "invisible",
      hidden2: "invisible",
      hidden3: "invisible",
      imgbream1: breeam1,
      imgbream2: breeam2,
      imgbream3: silver,
      position: { lat: 40.428444366714075, lng: -3.6978166940429906 },
        bimCoordination: '#',
        bimArchitecture: '#',
        bimInstallations: '#',
        bimMepMechanics: '#',
        bimMepPlumbing: '#',
        bimMepElectricity: '#',
        bimMepPci: '#',
        bimMepBms: '#',
        bimImplantations: '#',
        bimCad: '#',
        docWorkRecords: '#',
        docWorkCertificate: '#',
        docMaintenanceCertificate: '#',
        docGeotechnicalControl: '#',
        docControlInstallations: '#',
        docActivitiesCoordination: '#',
        docCorrectives: '#',
        docGeneral: '#',
        docAdministrativeLegal: '#',
        docRSC: '#',
        docPrevious: '#',
        docRisksEvaluation: '#',
        docISOs: '#',
        docImplementations: '#',
        docOCTReports: '#',
        docWorkReports:'#',
        docTechnicalReports: '#',
        docBuildingBook:'#',
        docLicenses: '#',
        docBiddingWorkContracts: '#',
        docSelfprotectionManuals: '#',
        docProjects:'#',
        docProcessingLegalization: '#',
        docFlats: '#',
        commercialFlats: '#',
        commercialSurfaces: '#',
        commercialProjectReport: '#',
        commercialCurrentPhase: '#',
        commercialRenders: '#',
        commercialAR: '#',
        stockStocks: '#',
        ecoCarbonFootprint: "#",
        ecoCertificate: "#",
        ecoProvisioning: "#",
        ecoEnergyEfficiency: "#",
        ecoWasteManagement: "#",
        ecoLifeCycle: "#",
        areaAeo: "#",
        areaTableAeo: "#",
        areaSurfaces: '#',
        tracingContract: "#",
        tracingCost: '#',
        tracingTerm: '#',
        tracingBudgets: '#',
        tracingCurrentPhase: '#',
        tracingScan: "#",
        buildingMemoryQuality: '#',
        buildingCFO: '#',
        buildingWorkRecords: '#',
        buildingCadastralReference: '#',
        buildingLicense: '#',
        buildingASBuilt: '#',
        buildingWellnessCertificate: '#',
        buildingPECOC: '#',
        buildingLegalizations: '#',
        buildingCompaniesInvolved: '#',
        buildingBuildingBook: '#',
        buildingPlanning: '#',
        buildingFlats: '#',
    },
    {
      id: "13",
      name: "Sagasta 31-33",
      address: "Calle Sagasta, 31-33",
      img_path: "madrid/sagasta_31-33.jpg",
      img: sagasta31,
      bream1: "Bream Parte I: Good",
      bream2: "Bream Parte II: Good",
      bream3: "Leed Silver",
      hidden: "",
      hidden2: "",
      imgbream1: breeam1,
      imgbream2: breeam2,
      imgbream3: silver,
      position: { lat: 40.42833770150753, lng: -3.69688445131838 },
        bimCoordination: '#',
        bimArchitecture: 'https://docs.b360.autodesk.com/projects/73f09b22-4703-45fd-bf6e-f26d5552e6eb/folders/urn:adsk.wipprod:fs.folder:co.J6DCAUm1Q4Wj-myTB49_ww/detail/viewer/items/urn:adsk.wipprod:dm.lineage:fHBHcjVORVyOXaoJVWTTPw',
        bimInstallations: '#',
        bimMepMechanics: '#',
        bimMepPlumbing: '#',
        bimMepElectricity: '#',
        bimMepPci: '#',
        bimMepBms: '#',
        bimImplantations: '#',
        bimCad: '#',
        docWorkRecords: '#',
        docWorkCertificate: '#',
        docMaintenanceCertificate: '#',
        docGeotechnicalControl: '#',
        docControlInstallations: '#',
        docActivitiesCoordination: '#',
        docCorrectives: '#',
        docGeneral: '#',
        docAdministrativeLegal: '#',
        docRSC: '#',
        docPrevious: '#',
        docRisksEvaluation: '#',
        docISOs: '#',
        docImplementations: '#',
        docOCTReports: '#',
        docWorkReports:'#',
        docTechnicalReports: '#',
        docBuildingBook:'#',
        docLicenses: '#',
        docBiddingWorkContracts: '#',
        docSelfprotectionManuals: '#',
        docProjects:'#',
        docProcessingLegalization: '#',
        docFlats: '#',
        commercialFlats: '#',
        commercialSurfaces: '#',
        commercialProjectReport: '#',
        commercialCurrentPhase: '#',
        commercialRenders: '#',
        commercialAR: '#',
        stockStocks: '#',
        ecoCarbonFootprint: "#",
        ecoCertificate: "#",
        ecoProvisioning: "#",
        ecoEnergyEfficiency: "#",
        ecoWasteManagement: "#",
        ecoLifeCycle: "#",
        areaAeo: "#",
        areaTableAeo: "#",
        areaSurfaces: '#',
        tracingContract: "#",
        tracingCost: '#',
        tracingTerm: '#',
        tracingBudgets: '#',
        tracingCurrentPhase: '#',
        tracingScan: "https://diseno9.viewin360.co/share/collection/7FqRf?logo=bWVkaWEvMzgyMjE0LzY0MDctNzNhNC0xMDkzLWIxMjMucG5n&card=1&info=0&logosize=187&fs=1&vr=1&sd=1&initload=0&thumbs=1",
        buildingMemoryQuality: '#',
        buildingCFO: '#',
        buildingWorkRecords: '#',
        buildingCadastralReference: '#',
        buildingLicense: '#',
        buildingASBuilt: '#',
        buildingWellnessCertificate: '#',
        buildingPECOC: '#',
        buildingLegalizations: '#',
        buildingCompaniesInvolved: '#',
        buildingBuildingBook: '#',
        buildingPlanning: '#',
        buildingFlats: '#',
    },
    {
      id: "14",
      name: "José Abascal 56",
      address: "Calle José Abascal 56",
      img_path: "madrid/jose_abascal_56.jpg",
      img: abascal56,
      bream1: "Bream Parte I: Very Good",
      bream2: "Bream Parte II: Excellent",
      bream3: "",
      hidden: "",
      hidden2: "invisible",
      imgbream1: breeam1,
      imgbream2: breeam2,
      imgbream3: platinum,
      position: { lat: 40.43810740851798, lng: -3.6922998797897484 },
        bimCoordination: '#',
        bimArchitecture: '#',
        bimInstallations: '#',
        bimMepMechanics: '#',
        bimMepPlumbing: '#',
        bimMepElectricity: '#',
        bimMepPci: '#',
        bimMepBms: '#',
        bimImplantations: '#',
        bimCad: '#',
        docWorkRecords: '#',
        docWorkCertificate: '#',
        docMaintenanceCertificate: '#',
        docGeotechnicalControl: '#',
        docControlInstallations: '#',
        docActivitiesCoordination: '#',
        docCorrectives: '#',
        docGeneral: '#',
        docAdministrativeLegal: '#',
        docRSC: '#',
        docPrevious: '#',
        docRisksEvaluation: '#',
        docISOs: '#',
        docImplementations: '#',
        docOCTReports: '#',
        docWorkReports:'#',
        docTechnicalReports: '#',
        docBuildingBook:'#',
        docLicenses: '#',
        docBiddingWorkContracts: '#',
        docSelfprotectionManuals: '#',
        docProjects:'#',
        docProcessingLegalization: '#',
        docFlats: '#',
        commercialFlats: '#',
        commercialSurfaces: '#',
        commercialProjectReport: '#',
        commercialCurrentPhase: '#',
        commercialRenders: '#',
        commercialAR: '#',
        stockStocks: '#',
        ecoCarbonFootprint: "#",
        ecoCertificate: "#",
        ecoProvisioning: "#",
        ecoEnergyEfficiency: "#",
        ecoWasteManagement: "#",
        ecoLifeCycle: "#",
        areaAeo: "#",
        areaTableAeo: "#",
        areaSurfaces: '#',
        tracingContract: "#",
        tracingCost: '#',
        tracingTerm: '#',
        tracingBudgets: '#',
        tracingCurrentPhase: '#',
        tracingScan: "#",
        buildingMemoryQuality: '#',
        buildingCFO: '#',
        buildingWorkRecords: '#',
        buildingCadastralReference: '#',
        buildingLicense: '#',
        buildingASBuilt: '#',
        buildingWellnessCertificate: '#',
        buildingPECOC: '#',
        buildingLegalizations: '#',
        buildingCompaniesInvolved: '#',
        buildingBuildingBook: '#',
        buildingPlanning: '#',
        buildingFlats: '#',
    },
    {
      id: "15",
      name: "José Abascal 45",
      address: "Calle José Abascal 45",
      img_path: "madrid/jose_abascal_45.jpg",
      img: abascal45,
      bream1: "Bream Parte I: Very Good",
      bream2: "Bream Parte II: Excellent",
      bream3: "",
      hidden: "",
      hidden2: "invisible",
      imgbream1: breeam1,
      imgbream2: breeam2,
      imgbream3: platinum,
      position: { lat: 40.438598278504166, lng: -3.695484581153312 },
        bimCoordination: '#',
        bimArchitecture: 'https://docs.b360.autodesk.com/projects/dde33a94-1209-4c27-9d1d-61b8e68c12e2/folders/urn:adsk.wipprod:fs.folder:co.JxvOBfliQpyCWhL9FOO6ww/detail/viewer/items/urn:adsk.wipprod:dm.lineage:-Q5dAM3URTm78PupdRzl3w',
        bimInstallations: '#',
        bimMepMechanics: '#',
        bimMepPlumbing: '#',
        bimMepElectricity: '#',
        bimMepPci: '#',
        bimMepBms: '#',
        bimImplantations: 'https://docs.b360.autodesk.com/projects/dde33a94-1209-4c27-9d1d-61b8e68c12e2/folders/urn:adsk.wipprod:fs.folder:co.07ou1VFHSE2JNdFWQXbr5Q/detail/viewer/items/urn:adsk.wipprod:dm.lineage:C79ZJxTtQn2q9iz0svj4xQ',
        bimCad: '#',
        docWorkRecords: '#',
        docWorkCertificate: '#',
        docMaintenanceCertificate: '#',
        docGeotechnicalControl: '#',
        docControlInstallations: '#',
        docActivitiesCoordination: '#',
        docCorrectives: '#',
        docGeneral: '#',
        docAdministrativeLegal: '#',
        docRSC: '#',
        docPrevious: '#',
        docRisksEvaluation: '#',
        docISOs: '#',
        docImplementations: '#',
        docOCTReports: '#',
        docWorkReports:'#',
        docTechnicalReports: '#',
        docBuildingBook:'#',
        docLicenses: '#',
        docBiddingWorkContracts: '#',
        docSelfprotectionManuals: '#',
        docProjects:'#',
        docProcessingLegalization: '#',
        docFlats: '#',
        commercialFlats: '#',
        commercialSurfaces: '#',
        commercialProjectReport: '#',
        commercialCurrentPhase: '#',
        commercialRenders: '#',
        commercialAR: '#',
        stockStocks: '#',
        ecoCarbonFootprint: "#",
        ecoCertificate: "#",
        ecoProvisioning: "#",
        ecoEnergyEfficiency: "#",
        ecoWasteManagement: "#",
        ecoLifeCycle: "#",
        areaAeo: "#",
        areaTableAeo: "#",
        areaSurfaces: '#',
        tracingContract: "#",
        tracingCost: '#',
        tracingTerm: '#',
        tracingBudgets: '#',
        tracingCurrentPhase: '#',
        tracingScan: "https://aec.cintoo.com/E2E27291B0F1E2439252",
        buildingMemoryQuality: '#',
        buildingCFO: '#',
        buildingWorkRecords: '#',
        buildingCadastralReference: '#',
        buildingLicense: '#',
        buildingASBuilt: '#',
        buildingWellnessCertificate: '#',
        buildingPECOC: '#',
        buildingLegalizations: '#',
        buildingCompaniesInvolved: '#',
        buildingBuildingBook: '#',
        buildingPlanning: '#',
        buildingFlats: '#',
    },
    {
      id: "16",
      name: "Santa Engracia",
      address: "Plaza Diego de Ordás, 3",
      img_path: "madrid/santa_engracia.jpg",
      img: santaEngracia,
      bream1: "Bream Parte I: Good",
      bream2: "Bream Parte II: Excellent",
      bream3: "",
      hidden: "",
      hidden2: "invisible",
      imgbream1: breeam1,
      imgbream2: breeam2,
      imgbream3: platinum,
      position: { lat: 40.44104932977647, lng: -3.7001202651368237 },
        bimCoordination: '#',
        bimArchitecture: '#',
        bimInstallations: '#',
        bimMepMechanics: '#',
        bimMepPlumbing: '#',
        bimMepElectricity: '#',
        bimMepPci: '#',
        bimMepBms: '#',
        bimImplantations: '#',
        bimCad: '#',
        docWorkRecords: '#',
        docWorkCertificate: '#',
        docMaintenanceCertificate: '#',
        docGeotechnicalControl: '#',
        docControlInstallations: '#',
        docActivitiesCoordination: '#',
        docCorrectives: '#',
        docGeneral: '#',
        docAdministrativeLegal: '#',
        docRSC: '#',
        docPrevious: '#',
        docRisksEvaluation: '#',
        docISOs: '#',
        docImplementations: '#',
        docOCTReports: '#',
        docWorkReports:'#',
        docTechnicalReports: '#',
        docBuildingBook:'#',
        docLicenses: '#',
        docBiddingWorkContracts: '#',
        docSelfprotectionManuals: '#',
        docProjects:'#',
        docProcessingLegalization: '#',
        docFlats: '#',
        commercialFlats: '#',
        commercialSurfaces: '#',
        commercialProjectReport: '#',
        commercialCurrentPhase: '#',
        commercialRenders: '#',
        commercialAR: '#',
        stockStocks: '#',
        ecoCarbonFootprint: "#",
        ecoCertificate: "#",
        ecoProvisioning: "#",
        ecoEnergyEfficiency: "#",
        ecoWasteManagement: "#",
        ecoLifeCycle: "#",
        areaAeo: "#",
        areaTableAeo: "#",
        areaSurfaces: '#',
        tracingContract: "#",
        tracingCost: '#',
        tracingTerm: '#',
        tracingBudgets: '#',
        tracingCurrentPhase: '#',
        tracingScan: "#",
        buildingMemoryQuality: '#',
        buildingCFO: '#',
        buildingWorkRecords: '#',
        buildingCadastralReference: '#',
        buildingLicense: '#',
        buildingASBuilt: '#',
        buildingWellnessCertificate: '#',
        buildingPECOC: '#',
        buildingLegalizations: '#',
        buildingCompaniesInvolved: '#',
        buildingBuildingBook: '#',
        buildingPlanning: '#',
        buildingFlats: '#',
    },
    {
      id: "17",
      name: "Velázquez 86d",
      address: "Calle Velázquez, 86d",
      img_path: "madrid/velazquez_86d.jpg",
      img: velazquez86,
      bream1: "Certificado en trámite",
      bream2: "",
      bream3: "",
      hidden: "invisible",
      hidden2: "invisible",
      imgbream1: gear,
      imgbream2: breeam2,
      imgbream3: platinum,
      position: { lat: 40.43189459068887, lng: -3.683465237247881 },
        bimCoordination: 'https://docs.b360.autodesk.com/projects/93b172db-092c-4b9a-9a7a-087a7f0b2ed3/folders/urn:adsk.wipprod:fs.folder:co.ReLfesfjRXW9ugW9L857BQ/detail/viewer/items/urn:adsk.wipprod:dm.lineage:h0hk-bDZTt-UZDHRmlKosQ',
        bimArchitecture: 'https://docs.b360.autodesk.com/projects/93b172db-092c-4b9a-9a7a-087a7f0b2ed3/folders/urn:adsk.wipprod:fs.folder:co.K_ItuxpZTk-BC5EesS7T3g/detail/viewer/items/urn:adsk.wipprod:dm.lineage:7pEpghVoQyaKMhaIEFWFAQ',
        bimInstallations: '#',
        bimMepMechanics: 'https://docs.b360.autodesk.com/projects/93b172db-092c-4b9a-9a7a-087a7f0b2ed3/folders/urn:adsk.wipprod:fs.folder:co.ycBWcyOGSF2mizoIkBIPww/detail/viewer/items/urn:adsk.wipprod:dm.lineage:QLA1uIC3RC2GaRs_-IWtXg',
        bimMepPlumbing: '#',
        bimMepElectricity: '#',
        bimMepPci: '#',
        bimMepBms: '#',
        bimImplantations: '#',
        bimCad: 'https://docs.b360.autodesk.com/projects/93b172db-092c-4b9a-9a7a-087a7f0b2ed3/folders/urn:adsk.wipprod:fs.folder:co.y2QNHSVQRhWQPegOzzZvgw/detail/viewer/items/urn:adsk.wipprod:dm.lineage:UEGpce6QSv6wLtgE09uc-Q',
        docWorkRecords: '#',
        docWorkCertificate: '#',
        docMaintenanceCertificate: '#',
        docGeotechnicalControl: '#',
        docControlInstallations: '#',
        docActivitiesCoordination: '#',
        docCorrectives: '#',
        docGeneral: '#',
        docAdministrativeLegal: '#',
        docRSC: '#',
        docPrevious: '#',
        docRisksEvaluation: '#',
        docISOs: '#',
        docImplementations: '#',
        docOCTReports: '#',
        docWorkReports:'#',
        docTechnicalReports: '#',
        docBuildingBook:'#',
        docLicenses: '#',
        docBiddingWorkContracts: '#',
        docSelfprotectionManuals: '#',
        docProjects:'#',
        docProcessingLegalization: '#',
        docFlats: '#',
        commercialFlats: '#',
        commercialSurfaces: '#',
        commercialProjectReport: '#',
        commercialCurrentPhase: '#',
        commercialRenders: '#',
        commercialAR: '#',
        stockStocks: '#',
        ecoCarbonFootprint: "#",
        ecoCertificate: "#",
        ecoProvisioning: "#",
        ecoEnergyEfficiency: "#",
        ecoWasteManagement: "#",
        ecoLifeCycle: "#",
        areaAeo: "#",
        areaTableAeo: "#",
        areaSurfaces: '#',
        tracingContract: "#",
        tracingCost: '#',
        tracingTerm: '#',
        tracingBudgets: '#',
        tracingCurrentPhase: '#',
        tracingScan: "#",
        buildingMemoryQuality: '#',
        buildingCFO: '#',
        buildingWorkRecords: '#',
        buildingCadastralReference: '#',
        buildingLicense: '#',
        buildingASBuilt: '#',
        buildingWellnessCertificate: '#',
        buildingPECOC: '#',
        buildingLegalizations: '#',
        buildingCompaniesInvolved: '#',
        buildingBuildingBook: '#',
        buildingPlanning: '#',
        buildingFlats: '#',
    },
    {
      id: "18",
      name: "Ramón Cruz",
      address: "Don Ramón de la Cruz, 82",
      img_path: "madrid/ramon_cruz_84.jpg",
      img: ramonCruz84,
      bream1: "Bream Parte I: Good",
      bream2: "Bream Parte II: Very Good",
      bream3: "Leed Platinum",
      hidden: "",
      hidden2: "",
      imgbream1: breeam1,
      imgbream2: breeam2,
      imgbream3: platinum,
      position: { lat: 40.42866329928476, lng: -3.6740663232150315 },
        bimCoordination: '#',
        bimArchitecture: '#',
        bimInstallations: '#',
        bimMepMechanics: '#',
        bimMepPlumbing: '#',
        bimMepElectricity: '#',
        bimMepPci: '#',
        bimMepBms: '#',
        bimImplantations: '#',
        bimCad: '#',
        docWorkRecords: '#',
        docWorkCertificate: '#',
        docMaintenanceCertificate: '#',
        docGeotechnicalControl: '#',
        docControlInstallations: '#',
        docActivitiesCoordination: '#',
        docCorrectives: '#',
        docGeneral: '#',
        docAdministrativeLegal: '#',
        docRSC: '#',
        docPrevious: '#',
        docRisksEvaluation: '#',
        docISOs: '#',
        docImplementations: '#',
        docOCTReports: '#',
        docWorkReports:'#',
        docTechnicalReports: '#',
        docBuildingBook:'#',
        docLicenses: '#',
        docBiddingWorkContracts: '#',
        docSelfprotectionManuals: '#',
        docProjects:'#',
        docProcessingLegalization: '#',
        docFlats: '#',
        commercialFlats: '#',
        commercialSurfaces: '#',
        commercialProjectReport: '#',
        commercialCurrentPhase: '#',
        commercialRenders: '#',
        commercialAR: '#',
        stockStocks: '#',
        ecoCarbonFootprint: "#",
        ecoCertificate: "#",
        ecoProvisioning: "#",
        ecoEnergyEfficiency: "#",
        ecoWasteManagement: "#",
        ecoLifeCycle: "#",
        areaAeo: "#",
        areaTableAeo: "#",
        areaSurfaces: '#',
        tracingContract: "#",
        tracingCost: '#',
        tracingTerm: '#',
        tracingBudgets: '#',
        tracingCurrentPhase: '#',
        tracingScan: "#",
        buildingMemoryQuality: '#',
        buildingCFO: '#',
        buildingWorkRecords: '#',
        buildingCadastralReference: '#',
        buildingLicense: '#',
        buildingASBuilt: '#',
        buildingWellnessCertificate: '#',
        buildingPECOC: '#',
        buildingLegalizations: '#',
        buildingCompaniesInvolved: '#',
        buildingBuildingBook: '#',
        buildingPlanning: '#',
        buildingFlats: '#',
    },
    {
      id: "19",
      name: "D. Building",
      address: "Estébanez Calderón, 3-5",
      img_path: "madrid/discovery_building.jpg",
      img: discovery,
      bream1: "Bream Parte I: Excellent",
      bream2: "Bream Parte II: Outstanding",
      bream3: "Leed Platinum",
      hidden: "",
      hidden2: "",
      imgbream1: breeam1,
      imgbream2: breeam2,
      imgbream3: platinum,
      position: { lat: 40.4635249531216, lng: -3.6909910510465886 },
        bimCoordination: '#',
        bimArchitecture: 'https://docs.b360.autodesk.com/projects/ae370825-fb9c-4414-a590-718eaad2b464/folders/urn:adsk.wipprod:fs.folder:co.NwOI4dqmRwOx0mYUPY1MRw/detail/viewer/items/urn:adsk.wipprod:dm.lineage:ksCph_raT0SfOIPtKXRpUA',
        bimInstallations: '#',
        bimMepMechanics: 'https://docs.b360.autodesk.com/projects/ae370825-fb9c-4414-a590-718eaad2b464/folders/urn:adsk.wipprod:fs.folder:co.ObLUFtA9TTKf564eBWDjhA/detail/viewer/items/urn:adsk.wipprod:dm.lineage:eJYqeduISyywcFEUBuDT4Q',
        bimMepPlumbing: 'https://docs.b360.autodesk.com/projects/ae370825-fb9c-4414-a590-718eaad2b464/folders/urn:adsk.wipprod:fs.folder:co.ObLUFtA9TTKf564eBWDjhA/detail/viewer/items/urn:adsk.wipprod:dm.lineage:xz0Rloa_TgC-z-rjmX8fQA',
        bimMepElectricity: '#',
        bimMepPci: '#',
        bimMepBms: '#',
        bimImplantations: '#',
        bimCad: 'https://docs.b360.autodesk.com/projects/ae370825-fb9c-4414-a590-718eaad2b464/folders/urn:adsk.wipprod:fs.folder:co.M9LkqgQbTamd5o9uz-XzdA/detail/viewer/items/urn:adsk.wipprod:dm.lineage:MCIpH_rBTqG3EFS0cfgJpw',
        docWorkRecords: '#',
        docWorkCertificate: '#',
        docMaintenanceCertificate: '#',
        docGeotechnicalControl: '#',
        docControlInstallations: '#',
        docActivitiesCoordination: '#',
        docCorrectives: '#',
        docGeneral: '#',
        docAdministrativeLegal: '#',
        docRSC: '#',
        docPrevious: '#',
        docRisksEvaluation: '#',
        docISOs: '#',
        docImplementations: '#',
        docOCTReports: '#',
        docWorkReports:'#',
        docTechnicalReports: '#',
        docBuildingBook:'#',
        docLicenses: '#',
        docBiddingWorkContracts: '#',
        docSelfprotectionManuals: '#',
        docProjects:'#',
        docProcessingLegalization: '#',
        docFlats: '#',
        commercialFlats: '#',
        commercialSurfaces: '#',
        commercialProjectReport: '#',
        commercialCurrentPhase: '#',
        commercialRenders: '#',
        commercialAR: '#',
        stockStocks: '#',
        ecoCarbonFootprint: "#",
        ecoCertificate: "#",
        ecoProvisioning: "#",
        ecoEnergyEfficiency: "#",
        ecoWasteManagement: "#",
        ecoLifeCycle: "#",
        areaAeo: "#",
        areaTableAeo: "#",
        areaSurfaces: '#',
        tracingContract: "#",
        tracingCost: '#',
        tracingTerm: '#',
        tracingBudgets: '#',
        tracingCurrentPhase: '#',
        tracingScan: "#",
        buildingMemoryQuality: '#',
        buildingCFO: '#',
        buildingWorkRecords: '#',
        buildingCadastralReference: '#',
        buildingLicense: '#',
        buildingASBuilt: '#',
        buildingWellnessCertificate: '#',
        buildingPECOC: '#',
        buildingLegalizations: '#',
        buildingCompaniesInvolved: '#',
        buildingBuildingBook: '#',
        buildingPlanning: '#',
        buildingFlats: '#',
    },
    {
      id: "20",
      name: "Joan Maragall",
      address: "Poeta Joan Maragall, 53",
      img_path: "madrid/joan_maragall_53.jpg",
      img: joanMaragall,
      bream1: "Bream Parte I: Good",
      bream2: "Bream Parte II: Excellent",
      bream3: "",
      hidden: "",
      hidden2: "invisible",
      imgbream1: breeam1,
      imgbream2: breeam2,
      imgbream3: gold,
      position: { lat: 40.463645465268634, lng: -3.6918471674590796 },
        bimCoordination: '#',
        bimArchitecture: '#',
        bimInstallations: '#',
        bimMepMechanics: '#',
        bimMepPlumbing: '#',
        bimMepElectricity: '#',
        bimMepPci: '#',
        bimMepBms: '#',
        bimImplantations: '#',
        bimCad: '#',
        docWorkRecords: '#',
        docWorkCertificate: '#',
        docMaintenanceCertificate: '#',
        docGeotechnicalControl: '#',
        docControlInstallations: '#',
        docActivitiesCoordination: '#',
        docCorrectives: '#',
        docGeneral: '#',
        docAdministrativeLegal: '#',
        docRSC: '#',
        docPrevious: '#',
        docRisksEvaluation: '#',
        docISOs: '#',
        docImplementations: '#',
        docOCTReports: '#',
        docWorkReports:'#',
        docTechnicalReports: '#',
        docBuildingBook:'#',
        docLicenses: '#',
        docBiddingWorkContracts: '#',
        docSelfprotectionManuals: '#',
        docProjects:'#',
        docProcessingLegalization: '#',
        docFlats: '#',
        commercialFlats: '#',
        commercialSurfaces: '#',
        commercialProjectReport: '#',
        commercialCurrentPhase: '#',
        commercialRenders: '#',
        commercialAR: '#',
        stockStocks: '#',
        ecoCarbonFootprint: "#",
        ecoCertificate: "#",
        ecoProvisioning: "#",
        ecoEnergyEfficiency: "#",
        ecoWasteManagement: "#",
        ecoLifeCycle: "#",
        areaAeo: "#",
        areaTableAeo: "#",
        areaSurfaces: '#',
        tracingContract: "#",
        tracingCost: '#',
        tracingTerm: '#',
        tracingBudgets: '#',
        tracingCurrentPhase: '#',
        tracingScan: "#",
        buildingMemoryQuality: '#',
        buildingCFO: '#',
        buildingWorkRecords: '#',
        buildingCadastralReference: '#',
        buildingLicense: '#',
        buildingASBuilt: '#',
        buildingWellnessCertificate: '#',
        buildingPECOC: '#',
        buildingLegalizations: '#',
        buildingCompaniesInvolved: '#',
        buildingBuildingBook: '#',
        buildingPlanning: '#',
        buildingFlats: '#',
    },
    {
      id: "21",
      name: "Manuel de Falla 7",
      address: "Calle Manuel de Falla, 7",
      img_path: "madrid/manuel_de_falla_7.jpg",
      img: manuelFalla,
      bream1: "Bream Parte I: Good",
      bream2: "Bream Parte II: Good",
      bream3: "Leed Gold",
      hidden: "",
      hidden2: "",
      imgbream1: breeam1,
      imgbream2: breeam2,
      imgbream3: gold,
      position: { lat: 40.4555711323253, lng: -3.687819237313171 },
        bimCoordination: '#',
        bimArchitecture: "https://docs.b360.autodesk.com/projects/a90dfd83-fa9a-46e6-ac37-3db401f155a1/folders/urn:adsk.wipprod:fs.folder:co.jKn-lS0NTACboqNnu4L_1w/detail/viewer/items/urn:adsk.wipprod:dm.lineage:pacKOj4BQ9qi9ASLjges1Q",
        bimInstallations: '#',
        bimMepMechanics: '#',
        bimMepPlumbing: '#',
        bimMepElectricity: '#',
        bimMepPci: '#',
        bimMepBms: '#',
        bimImplantations: '#',
        bimCad: '#',
        docWorkRecords: '#',
        docWorkCertificate: '#',
        docMaintenanceCertificate: '#',
        docGeotechnicalControl: '#',
        docControlInstallations: '#',
        docActivitiesCoordination: '#',
        docCorrectives: '#',
        docGeneral: '#',
        docAdministrativeLegal: '#',
        docRSC: '#',
        docPrevious: '#',
        docRisksEvaluation: '#',
        docISOs: '#',
        docImplementations: '#',
        docOCTReports: '#',
        docWorkReports:'#',
        docTechnicalReports: '#',
        docBuildingBook:'#',
        docLicenses: '#',
        docBiddingWorkContracts: '#',
        docSelfprotectionManuals: '#',
        docProjects:'#',
        docProcessingLegalization: '#',
        docFlats: '#',
        commercialFlats: '#',
        commercialSurfaces: '#',
        commercialProjectReport: '#',
        commercialCurrentPhase: '#',
        commercialRenders: '#',
        commercialAR: '#',
        stockStocks: '#',
        ecoCarbonFootprint: "#",
        ecoCertificate: "#",
        ecoProvisioning: "#",
        ecoEnergyEfficiency: "#",
        ecoWasteManagement: "#",
        ecoLifeCycle: "#",
        areaAeo: "https://docs.b360.autodesk.com/projects/a90dfd83-fa9a-46e6-ac37-3db401f155a1/folders/urn:adsk.wipprod:fs.folder:co.9VRbM9j4SpCN2xzmgnQ-Xw/detail/viewer/items/urn:adsk.wipprod:dm.lineage:dimo9BVKTJq485kfdbcXMw",
        areaTableAeo: "https://docs.b360.autodesk.com/projects/a90dfd83-fa9a-46e6-ac37-3db401f155a1/folders/urn:adsk.wipprod:fs.folder:co.-RdvcC8jTYuYTo70-aSz1g/detail/viewer/items/urn:adsk.wipprod:dm.lineage:wZYl_iG3RxiC2ivhtLAbQg",
        areaSurfaces: '#',
        tracingContract: "#",
        tracingCost: '#',
        tracingTerm: '#',
        tracingBudgets: '#',
        tracingCurrentPhase: '#',
        tracingScan: "#",
        buildingMemoryQuality: '#',
        buildingCFO: '#',
        buildingWorkRecords: '#',
        buildingCadastralReference: '#',
        buildingLicense: '#',
        buildingASBuilt: '#',
        buildingWellnessCertificate: '#',
        buildingPECOC: '#',
        buildingLegalizations: '#',
        buildingCompaniesInvolved: '#',
        buildingBuildingBook: '#',
        buildingPlanning: '#',
        buildingFlats: '#',
    },
    {
      id: "22",
      name: "Arturo Soria, 336",
      address: "Calle Arturo Soria, 336",
      img_path: "madrid/arturo_soria_336.jpg",
      img: arturoSoria336,
      bream1: "Bream Parte I: Very Good",
      bream2: "Bream Parte II: Excellent",
      bream3: "",
      hidden: "",
      hidden2: "invisible",
      imgbream1: breeam1,
      imgbream2: breeam2,
      imgbream3: gold,
      position: { lat: 40.48156232811399, lng: -3.665479811757029 },
        bimCoordination: 'https://docs.b360.autodesk.com/projects/2c160c7c-d750-4124-aa5e-423644e0861d/folders/urn:adsk.wipprod:fs.folder:co.jiDK27KYTYumH1imVyl8EQ/detail/viewer/items/urn:adsk.wipprod:dm.lineage:xJxFuvGIS7OcAm1T6Rmh2g',
        bimArchitecture: 'https://docs.b360.autodesk.com/projects/2c160c7c-d750-4124-aa5e-423644e0861d/folders/urn:adsk.wipprod:fs.folder:co.B_ai4dN1STGluIdjKHadYA/detail/viewer/items/urn:adsk.wipprod:dm.lineage:8-wLecO8T5em6UBKjFY3QQ',
        bimInstallations: 'https://docs.b360.autodesk.com/projects/2c160c7c-d750-4124-aa5e-423644e0861d/folders/urn:adsk.wipprod:fs.folder:co.zH8RvcLsQMONjxFbpvlvyA/detail/viewer/items/urn:adsk.wipprod:dm.lineage:WdACe3XsS22G0In9ABl7JQ',
        bimMepMechanics: 'https://docs.b360.autodesk.com/projects/2c160c7c-d750-4124-aa5e-423644e0861d/folders/urn:adsk.wipprod:fs.folder:co.zH8RvcLsQMONjxFbpvlvyA/detail/viewer/items/urn:adsk.wipprod:dm.lineage:zgrdVNNkRbuh3KW0QyXAHw',
        bimMepPlumbing: 'https://docs.b360.autodesk.com/projects/2c160c7c-d750-4124-aa5e-423644e0861d/folders/urn:adsk.wipprod:fs.folder:co.zH8RvcLsQMONjxFbpvlvyA/detail/viewer/items/urn:adsk.wipprod:dm.lineage:E1QZQIjqQYyvte4E96qzpg',
        bimMepElectricity: 'https://docs.b360.autodesk.com/projects/2c160c7c-d750-4124-aa5e-423644e0861d/folders/urn:adsk.wipprod:fs.folder:co.zH8RvcLsQMONjxFbpvlvyA/detail/viewer/items/urn:adsk.wipprod:dm.lineage:YjaVRMwSTw-ARCgOI7dVqQ',
        bimMepPci: 'https://docs.b360.autodesk.com/projects/2c160c7c-d750-4124-aa5e-423644e0861d/folders/urn:adsk.wipprod:fs.folder:co.zH8RvcLsQMONjxFbpvlvyA/detail/viewer/items/urn:adsk.wipprod:dm.lineage:p38E2AKfQgS8gDqTC0lBsw',
        bimMepBms: '#',
        bimImplantations: 'https://docs.b360.autodesk.com/projects/2c160c7c-d750-4124-aa5e-423644e0861d/folders/urn:adsk.wipprod:fs.folder:co.Z3yfEFc7TyecaOojBWdx_Q/detail/viewer/items/urn:adsk.wipprod:dm.lineage:X0ECs2X6RRWb0bqMhpxsDA',
        bimCad: '#',
        docWorkRecords: '#',
        docWorkCertificate: '#',
        docMaintenanceCertificate: '#',
        docGeotechnicalControl: '#',
        docControlInstallations: '#',
        docActivitiesCoordination: '#',
        docCorrectives: '#',
        docGeneral: '#',
        docAdministrativeLegal: '#',
        docRSC: '#',
        docPrevious: '#',
        docRisksEvaluation: '#',
        docISOs: '#',
        docImplementations: '#',
        docOCTReports: '#',
        docWorkReports:'#',
        docTechnicalReports: '#',
        docBuildingBook:'#',
        docLicenses: '#',
        docBiddingWorkContracts: '#',
        docSelfprotectionManuals: '#',
        docProjects:'#',
        docProcessingLegalization: '#',
        docFlats: '#',
        commercialFlats: '#',
        commercialSurfaces: '#',
        commercialProjectReport: '#',
        commercialCurrentPhase: '#',
        commercialRenders: '#',
        commercialAR: '#',
        stockStocks: '#',
        ecoCarbonFootprint: "#",
        ecoCertificate: "#",
        ecoProvisioning: "#",
        ecoEnergyEfficiency: "#",
        ecoWasteManagement: "#",
        ecoLifeCycle: "#",
        areaAeo: "#",
        areaTableAeo: "#",
        areaSurfaces: '#',
        tracingContract: "#",
        tracingCost: '#',
        tracingTerm: '#',
        tracingBudgets: '#',
        tracingCurrentPhase: '#',
        tracingScan: "https://aec.cintoo.com/925393D3D0507243D3E0",
        buildingMemoryQuality: '#',
        buildingCFO: '#',
        buildingWorkRecords: '#',
        buildingCadastralReference: '#',
        buildingLicense: '#',
        buildingASBuilt: '#',
        buildingWellnessCertificate: '#',
        buildingPECOC: '#',
        buildingLegalizations: '#',
        buildingCompaniesInvolved: '#',
        buildingBuildingBook: '#',
        buildingPlanning: '#',
        buildingFlats: '#',
    },
    {
      id: "23",
      name: "López de Hoyos",
      address: "Calle López de Hoyos 35",
      img_path: "madrid/lopez_hoyos_35.jpg",
      img: lopezHoyos,
      bream1: "Bream Parte I: Very Good",
      bream2: "Bream Parte II: Excellent",
      bream3: "",
      hidden: "",
      hidden2: "invisible",
      imgbream1: breeam1,
      imgbream2: breeam2,
      imgbream3: gold,
      position: { lat: 40.44132492937151, lng: -3.6798164229462786 },
        bimCoordination: '#',
        bimArchitecture: '#',
        bimInstallations: '#',
        bimMepMechanics: '#',
        bimMepPlumbing: '#',
        bimMepElectricity: '#',
        bimMepPci: '#',
        bimMepBms: '#',
        bimImplantations: '#',
        bimCad: '#',
        docWorkRecords: '#',
        docWorkCertificate: '#',
        docMaintenanceCertificate: '#',
        docGeotechnicalControl: '#',
        docControlInstallations: '#',
        docActivitiesCoordination: '#',
        docCorrectives: '#',
        docGeneral: '#',
        docAdministrativeLegal: '#',
        docRSC: '#',
        docPrevious: '#',
        docRisksEvaluation: '#',
        docISOs: '#',
        docImplementations: '#',
        docOCTReports: '#',
        docWorkReports:'#',
        docTechnicalReports: '#',
        docBuildingBook:'#',
        docLicenses: '#',
        docBiddingWorkContracts: '#',
        docSelfprotectionManuals: '#',
        docProjects:'#',
        docProcessingLegalization: '#',
        docFlats: '#',
        commercialFlats: '#',
        commercialSurfaces: '#',
        commercialProjectReport: '#',
        commercialCurrentPhase: '#',
        commercialRenders: '#',
        commercialAR: '#',
        stockStocks: '#',
        ecoCarbonFootprint: "#",
        ecoCertificate: "#",
        ecoProvisioning: "#",
        ecoEnergyEfficiency: "#",
        ecoWasteManagement: "#",
        ecoLifeCycle: "#",
        areaAeo: "#",
        areaTableAeo: "#",
        areaSurfaces: '#',
        tracingContract: "#",
        tracingCost: '#',
        tracingTerm: '#',
        tracingBudgets: '#',
        tracingCurrentPhase: '#',
        tracingScan: "#",
        buildingMemoryQuality: '#',
        buildingCFO: '#',
        buildingWorkRecords: '#',
        buildingCadastralReference: '#',
        buildingLicense: '#',
        buildingASBuilt: '#',
        buildingWellnessCertificate: '#',
        buildingPECOC: '#',
        buildingLegalizations: '#',
        buildingCompaniesInvolved: '#',
        buildingBuildingBook: '#',
        buildingPlanning: '#',
        buildingFlats: '#',
    },
    {
      id: "24",
      name: "Francisco Silvela",
      address: "Calle Francisco Silvela 42",
      img_path: "madrid/francisco_silvela_42.jpg",
      img: franciscoSivela,
      bream1: "Bream Parte I: Very Good",
      bream2: "Bream Parte II: Outstanding",
      bream3: "",
      hidden: "",
      hidden2: "invisible",
      imgbream1: breeam1,
      imgbream2: breeam2,
      imgbream3: gold,
      position: { lat: 40.43364548373846, lng: -3.6729308067816913 },
        bimCoordination: 'https://docs.b360.autodesk.com/projects/83f80a12-0d64-41f1-b433-f54c98bb4821/folders/urn:adsk.wipprod:fs.folder:co.mmGJLfR1T9KHcNj7vHVUfw/detail/viewer/items/urn:adsk.wipprod:dm.lineage:SeDXy6zPSqOkeXJWwJDm_w',
        bimArchitecture: 'https://docs.b360.autodesk.com/projects/83f80a12-0d64-41f1-b433-f54c98bb4821/folders/urn:adsk.wipprod:fs.folder:co.k34WIK6fQeCjYfKzWtFT1g/detail/viewer/items/urn:adsk.wipprod:dm.lineage:5b_j9hCEQO-ICaVmLPOYbQ',
        bimInstallations: '#',
        bimMepMechanics: 'https://docs.b360.autodesk.com/projects/83f80a12-0d64-41f1-b433-f54c98bb4821/folders/urn:adsk.wipprod:fs.folder:co.qlSbqhwbR8CrMQjOt8ywCQ/detail/viewer/items/urn:adsk.wipprod:dm.lineage:P_iGfBI-RDW3qi-GIKqRSg',
        bimMepPlumbing: '#',
        bimMepElectricity: 'https://docs.b360.autodesk.com/projects/83f80a12-0d64-41f1-b433-f54c98bb4821/folders/urn:adsk.wipprod:fs.folder:co.qlSbqhwbR8CrMQjOt8ywCQ/detail/viewer/items/urn:adsk.wipprod:dm.lineage:mp1c2UUJRRyVwSfSK7qg4Q',
        bimMepPci: 'https://docs.b360.autodesk.com/projects/83f80a12-0d64-41f1-b433-f54c98bb4821/folders/urn:adsk.wipprod:fs.folder:co.qlSbqhwbR8CrMQjOt8ywCQ/detail/viewer/items/urn:adsk.wipprod:dm.lineage:f1NTi4l8RbSXotQrEAW_aw',
        bimMepBms: '#',
        bimImplantations: '#',
        bimCad: 'https://docs.b360.autodesk.com/projects/83f80a12-0d64-41f1-b433-f54c98bb4821/folders/urn:adsk.wipprod:fs.folder:co.BYdxPkogQtmkDQqa8m1hmQ/detail/viewer/items/urn:adsk.wipprod:dm.lineage:S7zQnh1WRcKvWxk6SVoBcQ',
        docWorkRecords: '#',
        docWorkCertificate: '#',
        docMaintenanceCertificate: '#',
        docGeotechnicalControl: '#',
        docControlInstallations: '#',
        docActivitiesCoordination: '#',
        docCorrectives: '#',
        docGeneral: '#',
        docAdministrativeLegal: '#',
        docRSC: '#',
        docPrevious: '#',
        docRisksEvaluation: '#',
        docISOs: '#',
        docImplementations: '#',
        docOCTReports: '#',
        docWorkReports:'#',
        docTechnicalReports: '#',
        docBuildingBook:'#',
        docLicenses: '#',
        docBiddingWorkContracts: '#',
        docSelfprotectionManuals: '#',
        docProjects:'#',
        docProcessingLegalization: '#',
        docFlats: '#',
        commercialFlats: '#',
        commercialSurfaces: '#',
        commercialProjectReport: '#',
        commercialCurrentPhase: '#',
        commercialRenders: '#',
        commercialAR: '#',
        stockStocks: '#',
        ecoCarbonFootprint: "#",
        ecoCertificate: "#",
        ecoProvisioning: "#",
        ecoEnergyEfficiency: "#",
        ecoWasteManagement: "#",
        ecoLifeCycle: "#",
        areaAeo: "#",
        areaTableAeo: "#",
        areaSurfaces: '#',
        tracingContract: "#",
        tracingCost: '#',
        tracingTerm: '#',
        tracingBudgets: '#',
        tracingCurrentPhase: '#',
        tracingScan: "#",
        buildingMemoryQuality: '#',
        buildingCFO: '#',
        buildingWorkRecords: '#',
        buildingCadastralReference: '#',
        buildingLicense: '#',
        buildingASBuilt: '#',
        buildingWellnessCertificate: '#',
        buildingPECOC: '#',
        buildingLegalizations: '#',
        buildingCompaniesInvolved: '#',
        buildingBuildingBook: '#',
        buildingPlanning: '#',
        buildingFlats: '#',
    },
    {
      id: "25",
      name: "The Window Building",
      address: "Calle Príncipe de Vergara, 112",
      img_path: "madrid/the_window_building.jpg",
      img: window,
      bream1: "Leed Gold",
      bream2: "",
      bream3: "",
      hidden: "invisible",
      hidden2: "invisible",
      imgbream1: gold,
      imgbream2: breeam2,
      imgbream3: gold,
      position: { lat: 40.43993659534612, lng: -3.6782675351445295 },
        bimCoordination: '#',
        bimArchitecture: 'https://docs.b360.autodesk.com/projects/76e34fcb-63a4-449f-8968-69a89179e40c/folders/urn:adsk.wipprod:fs.folder:co.IUdqjq5ESuKp70BqPdwz-A/detail/viewer/items/urn:adsk.wipprod:dm.lineage:Xmh9wsu_SkiTgoF7hq1Cvw',
        bimInstallations: '#',
        bimMepMechanics: 'https://docs.b360.autodesk.com/projects/76e34fcb-63a4-449f-8968-69a89179e40c/folders/urn:adsk.wipprod:fs.folder:co.OjXJUeKWTVKZSDY0szdcjw/detail/viewer/items/urn:adsk.wipprod:dm.lineage:9e-ZLV01Q_mjvrkYXSqpSg',
        bimMepPlumbing: '#',
        bimMepElectricity: '#',
        bimMepPci: '#',
        bimMepBms: '#',
        bimImplantations: '#',
        bimCad: '#',
        docWorkRecords: '#',
        docWorkCertificate: '#',
        docMaintenanceCertificate: '#',
        docGeotechnicalControl: '#',
        docControlInstallations: '#',
        docActivitiesCoordination: '#',
        docCorrectives: '#',
        docGeneral: '#',
        docAdministrativeLegal: '#',
        docRSC: '#',
        docPrevious: '#',
        docRisksEvaluation: '#',
        docISOs: '#',
        docImplementations: '#',
        docOCTReports: '#',
        docWorkReports:'#',
        docTechnicalReports: '#',
        docBuildingBook:'#',
        docLicenses: '#',
        docBiddingWorkContracts: '#',
        docSelfprotectionManuals: '#',
        docProjects:'#',
        docProcessingLegalization: '#',
        docFlats: '#',
        commercialFlats: '#',
        commercialSurfaces: '#',
        commercialProjectReport: '#',
        commercialCurrentPhase: '#',
        commercialRenders: '#',
        commercialAR: '#',
        stockStocks: '#',
        ecoCarbonFootprint: "#",
        ecoCertificate: "#",
        ecoProvisioning: "#",
        ecoEnergyEfficiency: "#",
        ecoWasteManagement: "#",
        ecoLifeCycle: "#",
        areaAeo: "#",
        areaTableAeo: "#",
        areaSurfaces: '#',
        tracingContract: "#",
        tracingCost: '#',
        tracingTerm: '#',
        tracingBudgets: '#',
        tracingCurrentPhase: '#',
        tracingScan: "#",
        buildingMemoryQuality: '#',
        buildingCFO: '#',
        buildingWorkRecords: '#',
        buildingCadastralReference: '#',
        buildingLicense: '#',
        buildingASBuilt: '#',
        buildingWellnessCertificate: '#',
        buildingPECOC: '#',
        buildingLegalizations: '#',
        buildingCompaniesInvolved: '#',
        buildingBuildingBook: '#',
        buildingPlanning: '#',
        buildingFlats: '#',
    },
    {
      id: "26",
      name: "Santa Hortensia 26-28",
      address: "Calle Santa Hortensia 26-28",
      img_path: "madrid/santa_hortensia_26-28.jpg",
      img: santaHortensia,
      bream1: "",
      bream2: "",
      bream3: "",
      hidden: "invisible",
      hidden2: "invisible",
      hidden3: "invisible",
      imgbream1: breeam1,
      imgbream2: breeam2,
      imgbream3: gold,
      position: { lat: 40.444039881988, lng: -3.666214421874171 },
        bimCoordination: '#',
        bimArchitecture: '#',
        bimInstallations: '#',
        bimMepMechanics: '#',
        bimMepPlumbing: '#',
        bimMepElectricity: '#',
        bimMepPci: '#',
        bimMepBms: '#',
        bimImplantations: '#',
        bimCad: '#',
        docWorkRecords: '#',
        docWorkCertificate: '#',
        docMaintenanceCertificate: '#',
        docGeotechnicalControl: '#',
        docControlInstallations: '#',
        docActivitiesCoordination: '#',
        docCorrectives: '#',
        docGeneral: '#',
        docAdministrativeLegal: '#',
        docRSC: '#',
        docPrevious: '#',
        docRisksEvaluation: '#',
        docISOs: '#',
        docImplementations: '#',
        docOCTReports: '#',
        docWorkReports:'#',
        docTechnicalReports: '#',
        docBuildingBook:'#',
        docLicenses: '#',
        docBiddingWorkContracts: '#',
        docSelfprotectionManuals: '#',
        docProjects:'#',
        docProcessingLegalization: '#',
        docFlats: '#',
        commercialFlats: '#',
        commercialSurfaces: '#',
        commercialProjectReport: '#',
        commercialCurrentPhase: '#',
        commercialRenders: '#',
        commercialAR: '#',
        stockStocks: '#',
        ecoCarbonFootprint: "#",
        ecoCertificate: "#",
        ecoProvisioning: "#",
        ecoEnergyEfficiency: "#",
        ecoWasteManagement: "#",
        ecoLifeCycle: "#",
        areaAeo: "#",
        areaTableAeo: "#",
        areaSurfaces: '#',
        tracingContract: "#",
        tracingCost: '#',
        tracingTerm: '#',
        tracingBudgets: '#',
        tracingCurrentPhase: '#',
        tracingScan: "#",
        buildingMemoryQuality: '#',
        buildingCFO: '#',
        buildingWorkRecords: '#',
        buildingCadastralReference: '#',
        buildingLicense: '#',
        buildingASBuilt: '#',
        buildingWellnessCertificate: '#',
        buildingPECOC: '#',
        buildingLegalizations: '#',
        buildingCompaniesInvolved: '#',
        buildingBuildingBook: '#',
        buildingPlanning: '#',
        buildingFlats: '#',
    },
    {
      id: "27",
      name: "Ramírez Arellano 37",
      address: "Calle Ramírez Arellano 37",
      img_path: "madrid/ramirez_arellano_37.jpg",
      img: ramirez_arellano_37,
      bream1: "Bream Parte I: Good",
      bream2: "",
      bream3: "",
      hidden: "invisible",
      hidden2: "invisible",
      imgbream1: breeam1,
      imgbream2: breeam2,
      imgbream3: gold,
      position: { lat: 40.44630152617317, lng: -3.6579596823408904 },
        bimCoordination: '#',
        bimArchitecture: '#',
        bimInstallations: '#',
        bimMepMechanics: '#',
        bimMepPlumbing: '#',
        bimMepElectricity: '#',
        bimMepPci: '#',
        bimMepBms: '#',
        bimImplantations: '#',
        bimCad: '#',
        docWorkRecords: '#',
        docWorkCertificate: '#',
        docMaintenanceCertificate: '#',
        docGeotechnicalControl: '#',
        docControlInstallations: '#',
        docActivitiesCoordination: '#',
        docCorrectives: '#',
        docGeneral: '#',
        docAdministrativeLegal: '#',
        docRSC: '#',
        docPrevious: '#',
        docRisksEvaluation: '#',
        docISOs: '#',
        docImplementations: '#',
        docOCTReports: '#',
        docWorkReports:'#',
        docTechnicalReports: '#',
        docBuildingBook:'#',
        docLicenses: '#',
        docBiddingWorkContracts: '#',
        docSelfprotectionManuals: '#',
        docProjects:'#',
        docProcessingLegalization: '#',
        docFlats: '#',
        commercialFlats: '#',
        commercialSurfaces: '#',
        commercialProjectReport: '#',
        commercialCurrentPhase: '#',
        commercialRenders: '#',
        commercialAR: '#',
        stockStocks: '#',
        ecoCarbonFootprint: "#",
        ecoCertificate: "#",
        ecoProvisioning: "#",
        ecoEnergyEfficiency: "#",
        ecoWasteManagement: "#",
        ecoLifeCycle: "#",
        areaAeo: "#",
        areaTableAeo: "#",
        areaSurfaces: '#',
        tracingContract: "#",
        tracingCost: '#',
        tracingTerm: '#',
        tracingBudgets: '#',
        tracingCurrentPhase: '#',
        tracingScan: "#",
        buildingMemoryQuality: '#',
        buildingCFO: '#',
        buildingWorkRecords: '#',
        buildingCadastralReference: '#',
        buildingLicense: '#',
        buildingASBuilt: '#',
        buildingWellnessCertificate: '#',
        buildingPECOC: '#',
        buildingLegalizations: '#',
        buildingCompaniesInvolved: '#',
        buildingBuildingBook: '#',
        buildingPlanning: '#',
        buildingFlats: '#',
    },
    {
      id: "28",
      name: "Business Park",
      address: "Calle Martínez Villergas 49",
      img_path: "madrid/MV49.jpg",
      img: MV49,
      bream1: "Bream Parte I: Excellent",
      bream2: "Bream Parte II: Outstanding",
      bream3: "",
      hidden: "",
      hidden2: "invisible",
      imgbream1: breeam1,
      imgbream2: breeam2,
      imgbream3: gold,
      position: { lat: 40.44485991416341, lng: -3.6580966221432876 },
        bimCoordination: '#',
        bimArchitecture: '#',
        bimInstallations: '#',
        bimMepMechanics: '#',
        bimMepPlumbing: '#',
        bimMepElectricity: '#',
        bimMepPci: '#',
        bimMepBms: '#',
        bimImplantations: '#',
        bimCad: '#',
        docWorkRecords: '#',
        docWorkCertificate: '#',
        docMaintenanceCertificate: '#',
        docGeotechnicalControl: '#',
        docControlInstallations: '#',
        docActivitiesCoordination: '#',
        docCorrectives: '#',
        docGeneral: '#',
        docAdministrativeLegal: '#',
        docRSC: '#',
        docPrevious: '#',
        docRisksEvaluation: '#',
        docISOs: '#',
        docImplementations: '#',
        docOCTReports: '#',
        docWorkReports:'#',
        docTechnicalReports: '#',
        docBuildingBook:'#',
        docLicenses: '#',
        docBiddingWorkContracts: '#',
        docSelfprotectionManuals: '#',
        docProjects:'#',
        docProcessingLegalization: '#',
        docFlats: '#',
        commercialFlats: '#',
        commercialSurfaces: '#',
        commercialProjectReport: '#',
        commercialCurrentPhase: '#',
        commercialRenders: '#',
        commercialAR: '#',
        stockStocks: '#',
        ecoCarbonFootprint: "#",
        ecoCertificate: "#",
        ecoProvisioning: "#",
        ecoEnergyEfficiency: "#",
        ecoWasteManagement: "#",
        ecoLifeCycle: "#",
        areaAeo: "#",
        areaTableAeo: "#",
        areaSurfaces: '#',
        tracingContract: "#",
        tracingCost: '#',
        tracingTerm: '#',
        tracingBudgets: '#',
        tracingCurrentPhase: '#',
        tracingScan: "#",
        buildingMemoryQuality: '#',
        buildingCFO: '#',
        buildingWorkRecords: '#',
        buildingCadastralReference: '#',
        buildingLicense: '#',
        buildingASBuilt: '#',
        buildingWellnessCertificate: '#',
        buildingPECOC: '#',
        buildingLegalizations: '#',
        buildingCompaniesInvolved: '#',
        buildingBuildingBook: '#',
        buildingPlanning: '#',
        buildingFlats: '#',
    },
    {
      id: "29",
      name: "Ortega y Gasset",
      address: "Calle Ortega y Gasset 100",
      img_path: "madrid/Ortega_Gasset_100.jpg",
      img: Ortega_Gasset_100,
      bream1: "Certificado en trámite",
      bream2: "",
      bream3: "",
      hidden: "invisible",
      hidden2: "invisible",
      imgbream1: gear,
      imgbream2: breeam2,
      imgbream3: gold,
      position: { lat: 40.44770282192345, lng: -3.656901798059561 },
        bimCoordination: 'https://docs.b360.autodesk.com/projects/b7e22450-32e2-4e51-b99b-b46d12d6b682/folders/urn:adsk.wipprod:fs.folder:co.pNR4ZtV0SHiY-7ImdnI2Zw/detail/viewer/items/urn:adsk.wipprod:dm.lineage:8MfkceZoTuWfOIliW6wjTQ',
        bimArchitecture: 'https://docs.b360.autodesk.com/projects/b7e22450-32e2-4e51-b99b-b46d12d6b682/folders/urn:adsk.wipprod:fs.folder:co.xgG4F53iSOuvz9MQcuWiaA/detail/viewer/items/urn:adsk.wipprod:dm.lineage:X-OZK06mSPenEBGSjxTCkQ',
        bimMepMechanics: 'https://docs.b360.autodesk.com/projects/b7e22450-32e2-4e51-b99b-b46d12d6b682/folders/urn:adsk.wipprod:fs.folder:co.5rGXXruHQ0eVXePIFI0FIQ/detail/viewer/items/urn:adsk.wipprod:dm.lineage:sRersS3OThi5zUcGLYpziA',
        bimMepPlumbing: '#',
        bimMepElectricity: '#',
        bimMepPci: '#',
        bimMepBms: '#',
        bimInstallations: 'https://docs.b360.autodesk.com/projects/b7e22450-32e2-4e51-b99b-b46d12d6b682/folders/urn:adsk.wipprod:fs.folder:co.5rGXXruHQ0eVXePIFI0FIQ/detail/viewer/items/urn:adsk.wipprod:dm.lineage:sRersS3OThi5zUcGLYpziA',
        bimCad: 'https://docs.b360.autodesk.com/projects/b7e22450-32e2-4e51-b99b-b46d12d6b682/folders/urn:adsk.wipprod:fs.folder:co.9OGEzhyxSyuHnjle0cwVqA/detail/viewer/items/urn:adsk.wipprod:dm.lineage:Ut1OVdiIQkWoCjZQurGzEg',
        bimImplantations: '#',
        docWorkRecords: '#',
        docWorkCertificate: '#',
        docMaintenanceCertificate: '#',
        docGeotechnicalControl: '#',
        docControlInstallations: '#',
        docActivitiesCoordination: '#',
        docCorrectives: '#',
        docGeneral: '#',
        docAdministrativeLegal: '#',
        docRSC: '#',
        docPrevious: '#',
        docRisksEvaluation: '#',
        docISOs: '#',
        docImplementations: '#',
        docOCTReports: '#',
        docWorkReports:'#',
        docTechnicalReports: '#',
        docBuildingBook:'#',
        docLicenses: '#',
        docBiddingWorkContracts: '#',
        docSelfprotectionManuals: '#',
        docProjects:'#',
        docProcessingLegalization: '#',
        docFlats: '#',
        commercialFlats: '#',
        commercialSurfaces: '#',
        commercialProjectReport: '#',
        commercialCurrentPhase: '#',
        commercialRenders: '#',
        commercialAR: '#',
        stockStocks: '#',
        ecoCarbonFootprint: "#",
        ecoCertificate: "#",
        ecoProvisioning: "#",
        ecoEnergyEfficiency: "#",
        ecoWasteManagement: "#",
        ecoLifeCycle: "#",
        areaAeo: "https://docs.b360.autodesk.com/projects/b7e22450-32e2-4e51-b99b-b46d12d6b682/folders/urn:adsk.wipprod:fs.folder:co.094LL-mDRViNzVPHCZxyZg/detail/viewer/items/urn:adsk.wipprod:dm.lineage:DyLpBUr9RL-G8vlHT3uV3g",
        areaTableAeo: "https://docs.b360.autodesk.com/projects/b7e22450-32e2-4e51-b99b-b46d12d6b682/folders/urn:adsk.wipprod:fs.folder:co.-E-wTm5RQ9u5idoEW6G9hQ/detail/viewer/items/urn:adsk.wipprod:dm.lineage:WGh-6pMJTxuKkDp3w5dxCw",
        areaSurfaces: '#',
        tracingContract: "#",
        tracingCost: '#',
        tracingTerm: '#',
        tracingBudgets: '#',
        tracingCurrentPhase: '#',
        tracingScan: "#",
        buildingMemoryQuality: '#',
        buildingCFO: '#',
        buildingWorkRecords: '#',
        buildingCadastralReference: '#',
        buildingLicense: '#',
        buildingASBuilt: '#',
        buildingWellnessCertificate: '#',
        buildingPECOC: '#',
        buildingLegalizations: '#',
        buildingCompaniesInvolved: '#',
        buildingBuildingBook: '#',
        buildingPlanning: '#',
        buildingFlats: '#',
    },
    {
      id: "30",
      name: "Edificio Egeo",
      address: "Avenida Partenón 4-6",
      img_path: "madrid/egeo.jpg",
      img: egeo,
      bream1: "",
      bream2: "",
      bream3: "",
      hidden: "invisible",
      hidden2: "invisible",
      hidden3: "invisible",
      imgbream1: breeam1,
      imgbream2: breeam2,
      imgbream3: gold,
      position: { lat: 40.46322562688905, lng: -3.618194820325572 },
        bimCoordination: 'https://docs.b360.autodesk.com/projects/0528b331-510e-4cb2-8752-9ab0532d8214/folders/urn:adsk.wipprod:fs.folder:co.KbFAPgAlTO6nby81XF9nQg/detail/viewer/items/urn:adsk.wipprod:dm.lineage:-EMcBPkBSuyNqzEjUri_7A',
        bimArchitecture: 'https://docs.b360.autodesk.com/projects/0528b331-510e-4cb2-8752-9ab0532d8214/folders/urn:adsk.wipprod:fs.folder:co.B7EItehyR4-k9UiaTJ5HDQ/detail/viewer/items/urn:adsk.wipprod:dm.lineage:WCjJSSwBTi2KgbUGyWOP1A',
        bimInstallations: '#',
        bimMepMechanics: 'https://docs.b360.autodesk.com/projects/0528b331-510e-4cb2-8752-9ab0532d8214/folders/urn:adsk.wipprod:fs.folder:co.6Me1KmAPTK29BVYIEXqV1A/detail/viewer/items/urn:adsk.wipprod:dm.lineage:Q1Bn13bdRRqOh0EK3RmcOQ',
        bimMepPlumbing: 'https://docs.b360.autodesk.com/projects/0528b331-510e-4cb2-8752-9ab0532d8214/folders/urn:adsk.wipprod:fs.folder:co.6Me1KmAPTK29BVYIEXqV1A/detail/viewer/items/urn:adsk.wipprod:dm.lineage:cWaf9xj8SMqyYmrlPo6Yhw',
        bimMepElectricity: 'https://docs.b360.autodesk.com/projects/0528b331-510e-4cb2-8752-9ab0532d8214/folders/urn:adsk.wipprod:fs.folder:co.6Me1KmAPTK29BVYIEXqV1A/detail/viewer/items/urn:adsk.wipprod:dm.lineage:TfaYfUtRT1W0mqgK0v2VNA',
        bimMepPci: 'https://docs.b360.autodesk.com/projects/0528b331-510e-4cb2-8752-9ab0532d8214/folders/urn:adsk.wipprod:fs.folder:co.6Me1KmAPTK29BVYIEXqV1A/detail/viewer/items/urn:adsk.wipprod:dm.lineage:dqmGKa5_SOqDfBolhYezkA',
        bimMepBms: '#',
        bimImplantations: '#',
        bimCad: 'https://docs.b360.autodesk.com/projects/0528b331-510e-4cb2-8752-9ab0532d8214/folders/urn:adsk.wipprod:fs.folder:co.Ebktsbi9QAahhzLma_hfKw/detail/viewer/items/urn:adsk.wipprod:dm.lineage:xUr_8H6_T26odMnqyj0q7g',
        docWorkRecords: '#',
        docWorkCertificate: '#',
        docMaintenanceCertificate: '#',
        docGeotechnicalControl: '#',
        docControlInstallations: '#',
        docActivitiesCoordination: '#',
        docCorrectives: '#',
        docGeneral: '#',
        docAdministrativeLegal: '#',
        docRSC: '#',
        docPrevious: '#',
        docRisksEvaluation: '#',
        docISOs: '#',
        docImplementations: '#',
        docOCTReports: '#',
        docWorkReports:'#',
        docTechnicalReports: '#',
        docBuildingBook:'#',
        docLicenses: '#',
        docBiddingWorkContracts: '#',
        docSelfprotectionManuals: '#',
        docProjects:'#',
        docProcessingLegalization: '#',
        docFlats: '#',
        commercialFlats: '#',
        commercialSurfaces: '#',
        commercialProjectReport: '#',
        commercialCurrentPhase: '#',
        commercialRenders: '#',
        commercialAR: '#',
        stockStocks: '#',
        ecoCarbonFootprint: "#",
        ecoCertificate: "#",
        ecoProvisioning: "#",
        ecoEnergyEfficiency: "#",
        ecoWasteManagement: "#",
        ecoLifeCycle: "#",
        areaAeo: "#",
        areaTableAeo: "#",
        areaSurfaces: '#',
        tracingContract: "#",
        tracingCost: '#',
        tracingTerm: '#',
        tracingBudgets: '#',
        tracingCurrentPhase: '#',
        tracingScan: "#",
        buildingMemoryQuality: '#',
        buildingCFO: '#',
        buildingWorkRecords: '#',
        buildingCadastralReference: '#',
        buildingLicense: '#',
        buildingASBuilt: '#',
        buildingWellnessCertificate: '#',
        buildingPECOC: '#',
        buildingLegalizations: '#',
        buildingCompaniesInvolved: '#',
        buildingBuildingBook: '#',
        buildingPlanning: '#',
        buildingFlats: '#',
    },
    {
      id: "31",
      name: "Alfonso XII, 62",
      address: "Calle Alfonso XII, 62",
      img_path: "madrid/alfonso_XII_62.jpg",
      img: alfonso_XII_62,
      bream1: "Bream Parte I: Very Good",
      bream2: "Bream Parte II: Outstanding",
      bream3: "",
      hidden: "",
      hidden2: "invisible",
      imgbream1: breeam1,
      imgbream2: breeam2,
      imgbream3: gold,
      position: { lat: 40.40881709312449, lng: -3.6889146648717057 },
        bimCoordination: '#',
        bimArchitecture: 'https://docs.b360.autodesk.com/projects/ffb59b3f-1156-4973-b094-6baac5f745fb/folders/urn:adsk.wipprod:fs.folder:co.P7Sw5l2aQG2UQStklH53Dw/detail/viewer/items/urn:adsk.wipprod:dm.lineage:4u3UfeFCSc-WybgRUkl5AQ',
        bimInstallations: '#',
        bimMepMechanics: '#',
        bimMepPlumbing: '#',
        bimMepElectricity: '#',
        bimMepPci: '#',
        bimMepBms: '#',
        bimImplantations: 'https://docs.b360.autodesk.com/projects/ffb59b3f-1156-4973-b094-6baac5f745fb/folders/urn:adsk.wipprod:fs.folder:co.in4V_0ZzRmSxL0_XrBoYvQ/detail/viewer/items/urn:adsk.wipprod:dm.lineage:oNEolAPpQriJKRgpQne0hg',
        bimCad: '#',
        docWorkRecords: '#',
        docWorkCertificate: '#',
        docMaintenanceCertificate: '#',
        docGeotechnicalControl: '#',
        docControlInstallations: '#',
        docActivitiesCoordination: '#',
        docCorrectives: '#',
        docGeneral: '#',
        docAdministrativeLegal: '#',
        docRSC: '#',
        docPrevious: '#',
        docRisksEvaluation: '#',
        docISOs: '#',
        docImplementations: '#',
        docOCTReports: '#',
        docWorkReports:'#',
        docTechnicalReports: '#',
        docBuildingBook:'#',
        docLicenses: '#',
        docBiddingWorkContracts: '#',
        docSelfprotectionManuals: '#',
        docProjects:'#',
        docProcessingLegalization: '#',
        docFlats: '#',
        commercialFlats: '#',
        commercialSurfaces: '#',
        commercialProjectReport: '#',
        commercialCurrentPhase: '#',
        commercialRenders: '#',
        commercialAR: '#',
        stockStocks: '#',
        ecoCarbonFootprint: "#",
        ecoCertificate: "#",
        ecoProvisioning: "#",
        ecoEnergyEfficiency: "#",
        ecoWasteManagement: "#",
        ecoLifeCycle: "#",
        areaAeo: "#",
        areaTableAeo: "#",
        areaSurfaces: '#',
        tracingContract: "#",
        tracingCost: '#',
        tracingTerm: '#',
        tracingBudgets: '#',
        tracingCurrentPhase: '#',
        tracingScan: "#",
        buildingMemoryQuality: '#',
        buildingCFO: '#',
        buildingWorkRecords: '#',
        buildingCadastralReference: '#',
        buildingLicense: '#',
        buildingASBuilt: '#',
        buildingWellnessCertificate: '#',
        buildingPECOC: '#',
        buildingLegalizations: '#',
        buildingCompaniesInvolved: '#',
        buildingBuildingBook: '#',
        buildingPlanning: '#',
        buildingFlats: '#',
    },
    {
      id: "32",
      name: "Puerto Somport 8",
      address: "Puerto de Somport 8",
      img_path: "madrid/Puerto_Somport_8.jpg",
      img: Puerto_Somport_8,
      bream1: "",
      bream2: "",
      bream3: "",
      hidden: "invisible",
      hidden2: "invisible",
      hidden3: "invisible",
      imgbream1: breeam1,
      imgbream2: breeam2,
      imgbream3: gold,
      position: { lat: 40.495362413243456, lng: -3.672932881010504 },
        bimCoordination: '#',
        bimArchitecture: '#',
        bimInstallations: '#',
        bimMepMechanics: '#',
        bimMepPlumbing: '#',
        bimMepElectricity: '#',
        bimMepPci: '#',
        bimMepBms: '#',
        bimImplantations: '#',
        bimCad: '#',
        docWorkRecords: '#',
        docWorkCertificate: '#',
        docMaintenanceCertificate: '#',
        docGeotechnicalControl: '#',
        docControlInstallations: '#',
        docActivitiesCoordination: '#',
        docCorrectives: '#',
        docGeneral: '#',
        docAdministrativeLegal: '#',
        docRSC: '#',
        docPrevious: '#',
        docRisksEvaluation: '#',
        docISOs: '#',
        docImplementations: '#',
        docOCTReports: '#',
        docWorkReports:'#',
        docTechnicalReports: '#',
        docBuildingBook:'#',
        docLicenses: '#',
        docBiddingWorkContracts: '#',
        docSelfprotectionManuals: '#',
        docProjects:'#',
        docProcessingLegalization: '#',
        docFlats: '#',
        commercialFlats: '#',
        commercialSurfaces: '#',
        commercialProjectReport: '#',
        commercialCurrentPhase: '#',
        commercialRenders: '#',
        commercialAR: '#',
        stockStocks: '#',
        ecoCarbonFootprint: "#",
        ecoCertificate: "#",
        ecoProvisioning: "#",
        ecoEnergyEfficiency: "#",
        ecoWasteManagement: "#",
        ecoLifeCycle: "#",
        areaAeo: "#",
        areaTableAeo: "#",
        areaSurfaces: '#',
        tracingContract: "#",
        tracingCost: '#',
        tracingTerm: '#',
        tracingBudgets: '#',
        tracingCurrentPhase: '#',
        tracingScan: "#",
        buildingMemoryQuality: '#',
        buildingCFO: '#',
        buildingWorkRecords: '#',
        buildingCadastralReference: '#',
        buildingLicense: '#',
        buildingASBuilt: '#',
        buildingWellnessCertificate: '#',
        buildingPECOC: '#',
        buildingLegalizations: '#',
        buildingCompaniesInvolved: '#',
        buildingBuildingBook: '#',
        buildingPlanning: '#',
        buildingFlats: '#',
    },
    {
      id: "33",
      name: "Puerto Somport 10",
      address: "Puerto de Somport, 10-18",
      img_path: "madrid/puerto_somport_10-18.jpg",
      img: puerto_somport_10,
      bream1: "Bream Parte I: Good",
      bream2: "Bream Parte II: Good",
      bream3: "",
      hidden: "",
      hidden2: "invisible",
      imgbream1: breeam1,
      imgbream2: breeam2,
      position: { lat: 40.49551533172665, lng: -3.6728359775402386 },
        bimCoordination: '#',
        bimArchitecture: '#',
        bimInstallations: '#',
        bimMepMechanics: '#',
        bimMepPlumbing: '#',
        bimMepElectricity: '#',
        bimMepPci: '#',
        bimMepBms: '#',
        bimImplantations: '#',
        bimCad: '#',
        docWorkRecords: '#',
        docWorkCertificate: '#',
        docMaintenanceCertificate: '#',
        docGeotechnicalControl: '#',
        docControlInstallations: '#',
        docActivitiesCoordination: '#',
        docCorrectives: '#',
        docGeneral: '#',
        docAdministrativeLegal: '#',
        docRSC: '#',
        docPrevious: '#',
        docRisksEvaluation: '#',
        docISOs: '#',
        docImplementations: '#',
        docOCTReports: '#',
        docWorkReports:'#',
        docTechnicalReports: '#',
        docBuildingBook:'#',
        docLicenses: '#',
        docBiddingWorkContracts: '#',
        docSelfprotectionManuals: '#',
        docProjects:'#',
        docProcessingLegalization: '#',
        docFlats: '#',
        commercialFlats: '#',
        commercialSurfaces: '#',
        commercialProjectReport: '#',
        commercialCurrentPhase: '#',
        commercialRenders: '#',
        commercialAR: '#',
        stockStocks: '#',
        ecoCarbonFootprint: "#",
        ecoCertificate: "#",
        ecoProvisioning: "#",
        ecoEnergyEfficiency: "#",
        ecoWasteManagement: "#",
        ecoLifeCycle: "#",
        areaAeo: "#",
        areaTableAeo: "#",
        areaSurfaces: '#',
        tracingContract: "#",
        tracingCost: '#',
        tracingTerm: '#',
        tracingBudgets: '#',
        tracingCurrentPhase: '#',
        tracingScan: "#",
        buildingMemoryQuality: '#',
        buildingCFO: '#',
        buildingWorkRecords: '#',
        buildingCadastralReference: '#',
        buildingLicense: '#',
        buildingASBuilt: '#',
        buildingWellnessCertificate: '#',
        buildingPECOC: '#',
        buildingLegalizations: '#',
        buildingCompaniesInvolved: '#',
        buildingBuildingBook: '#',
        buildingPlanning: '#',
        buildingFlats: '#',
    },
    {
      id: "34",
      name: "Cedro",
      address: "Anabel Segura, 14",
      img_path: "/madrid/cedro.jpg",
      img: cedro,
      bream1: "Bream Parte I: Good",
      bream2: "Bream Parte II: Good",
      bream3: "Leed Gold",
      hidden: "",
      hidden2: "",
      imgbream1: breeam1,
      imgbream2: breeam2,
      imgbream3: gold,
      position: { lat: 40.53391826697793, lng: -3.627854035675873 },
        bimCoordination: '#',
        bimArchitecture: 'https://docs.b360.autodesk.com/projects/36624a3e-178c-477e-89f5-eec2d81b923e/folders/urn:adsk.wipprod:fs.folder:co.F5vOz66oTyqY5bzTiDVc9g/detail/viewer/items/urn:adsk.wipprod:dm.lineage:CVuT9B7ASiW6lWADeccbmA',
        bimInstallations: '#',
        bimMepMechanics: '#',
        bimMepPlumbing: '#',
        bimMepElectricity: '#',
        bimMepPci: '#',
        bimMepBms: '#',
        bimImplantations: '#',
        bimCad: '#',
        docWorkRecords: '#',
        docWorkCertificate: '#',
        docMaintenanceCertificate: '#',
        docGeotechnicalControl: '#',
        docControlInstallations: '#',
        docActivitiesCoordination: '#',
        docCorrectives: '#',
        docGeneral: '#',
        docAdministrativeLegal: '#',
        docRSC: '#',
        docPrevious: '#',
        docRisksEvaluation: '#',
        docISOs: '#',
        docImplementations: '#',
        docOCTReports: '#',
        docWorkReports:'#',
        docTechnicalReports: '#',
        docBuildingBook:'#',
        docLicenses: '#',
        docBiddingWorkContracts: '#',
        docSelfprotectionManuals: '#',
        docProjects:'#',
        docProcessingLegalization: '#',
        docFlats: '#',
        commercialFlats: '#',
        commercialSurfaces: '#',
        commercialProjectReport: '#',
        commercialCurrentPhase: '#',
        commercialRenders: '#',
        commercialAR: '#',
        stockStocks: '#',
        ecoCarbonFootprint: "#",
        ecoCertificate: "#",
        ecoProvisioning: "#",
        ecoEnergyEfficiency: "#",
        ecoWasteManagement: "#",
        ecoLifeCycle: "#",
        areaAeo: "#",
        areaTableAeo: "#",
        areaSurfaces: '#',
        tracingContract: "#",
        tracingCost: '#',
        tracingTerm: '#',
        tracingBudgets: '#',
        tracingCurrentPhase: '#',
        tracingScan: "#",
        buildingMemoryQuality: '#',
        buildingCFO: '#',
        buildingWorkRecords: '#',
        buildingCadastralReference: '#',
        buildingLicense: '#',
        buildingASBuilt: '#',
        buildingWellnessCertificate: '#',
        buildingPECOC: '#',
        buildingLegalizations: '#',
        buildingCompaniesInvolved: '#',
        buildingBuildingBook: '#',
        buildingPlanning: '#',
        buildingFlats: '#',
    },
    {
      id: "35",
      name: "Oblicua",
      address: "Francisca Delgado, 11",
      img_path: "madrid/oblicua.jpg",
      img: oblicua,
      bream1: "Bream Parte I: Very Good",
      bream2: "Bream Parte II: Good",
      bream3: "Leed Gold",
      hidden: "",
      hidden2: "",
      imgbream1: breeam1,
      imgbream2: breeam2,
      imgbream3: gold,
      position: { lat: 40.53368100795848, lng: -3.6294961700118282 },
        bimCoordination: '#',
        bimArchitecture: '#',
        bimInstallations: '#',
        bimMepMechanics: '#',
        bimMepPlumbing: '#',
        bimMepElectricity: '#',
        bimMepPci: '#',
        bimMepBms: '#',
        bimImplantations: '#',
        bimCad: '#',
        docWorkRecords: '#',
        docWorkCertificate: '#',
        docMaintenanceCertificate: '#',
        docGeotechnicalControl: '#',
        docControlInstallations: '#',
        docActivitiesCoordination: '#',
        docCorrectives: '#',
        docGeneral: '#',
        docAdministrativeLegal: '#',
        docRSC: '#',
        docPrevious: '#',
        docRisksEvaluation: '#',
        docISOs: '#',
        docImplementations: '#',
        docOCTReports: '#',
        docWorkReports:'#',
        docTechnicalReports: '#',
        docBuildingBook:'#',
        docLicenses: '#',
        docBiddingWorkContracts: '#',
        docSelfprotectionManuals: '#',
        docProjects:'#',
        docProcessingLegalization: '#',
        docFlats: '#',
        commercialFlats: '#',
        commercialSurfaces: '#',
        commercialProjectReport: '#',
        commercialCurrentPhase: '#',
        commercialRenders: '#',
        commercialAR: '#',
        stockStocks: '#',
        ecoCarbonFootprint: "#",
        ecoCertificate: "#",
        ecoProvisioning: "#",
        ecoEnergyEfficiency: "#",
        ecoWasteManagement: "#",
        ecoLifeCycle: "#",
        areaAeo: "#",
        areaTableAeo: "#",
        areaSurfaces: '#',
        tracingContract: "#",
        tracingCost: '#',
        tracingTerm: '#',
        tracingBudgets: '#',
        tracingCurrentPhase: '#',
        tracingScan: "#",
        buildingMemoryQuality: '#',
        buildingCFO: '#',
        buildingWorkRecords: '#',
        buildingCadastralReference: '#',
        buildingLicense: '#',
        buildingASBuilt: '#',
        buildingWellnessCertificate: '#',
        buildingPECOC: '#',
        buildingLegalizations: '#',
        buildingCompaniesInvolved: '#',
        buildingBuildingBook: '#',
        buildingPlanning: '#',
        buildingFlats: '#',
    },
    {
      id: "36",
      name: "Ribera del Loira",
      address: "Calle Ribera del Loira, 28",
      img_path: "madrid/Ribera_Loira_28.jpg",
      img: Ribera_Loira_28,
      bream1: "Bream Parte I: Good",
      bream2: "Bream Parte II: Very Good",
      bream3: "Leed Gold",
      hidden: "",
      hidden2: "",
      imgbream1: breeam1,
      imgbream2: breeam2,
      imgbream3: gold,
      position: { lat: 40.46407323862491, lng: -3.6232694679802453 },
        bimCoordination: '#',
        bimArchitecture: 'https://docs.b360.autodesk.com/projects/018cb7f3-42e5-4b04-9fd4-006a76e11c65/folders/urn:adsk.wipprod:fs.folder:co.GPKegUGBRiqSNIQErvSdOw/detail/viewer/items/urn:adsk.wipprod:dm.lineage:9I_jMGrzTfy-0zYfE6FzKg',
        bimInstallations: '#',
        bimMepMechanics: '#',
        bimMepPlumbing: '#',
        bimMepElectricity: '#',
        bimMepPci: '#',
        bimMepBms: '#',
        bimImplantations: 'https://docs.b360.autodesk.com/projects/018cb7f3-42e5-4b04-9fd4-006a76e11c65/folders/urn:adsk.wipprod:fs.folder:co.ScDo4q0GTO-dr_qwBFi0FA/detail/viewer/items/urn:adsk.wipprod:dm.lineage:bfqS5Iw2T5CzzAtrhPrFqg',
        bimCad: '#',
        docWorkRecords: '#',
        docWorkCertificate: '#',
        docMaintenanceCertificate: '#',
        docGeotechnicalControl: '#',
        docControlInstallations: '#',
        docActivitiesCoordination: '#',
        docCorrectives: '#',
        docGeneral: '#',
        docAdministrativeLegal: '#',
        docRSC: '#',
        docPrevious: '#',
        docRisksEvaluation: '#',
        docISOs: '#',
        docImplementations: '#',
        docOCTReports: '#',
        docWorkReports:'#',
        docTechnicalReports: '#',
        docBuildingBook:'#',
        docLicenses: '#',
        docBiddingWorkContracts: '#',
        docSelfprotectionManuals: '#',
        docProjects:'#',
        docProcessingLegalization: '#',
        docFlats: '#',
        commercialFlats: '#',
        commercialSurfaces: '#',
        commercialProjectReport: '#',
        commercialCurrentPhase: '#',
        commercialRenders: '#',
        commercialAR: '#',
        stockStocks: '#',
        ecoCarbonFootprint: "#",
        ecoCertificate: "#",
        ecoProvisioning: "#",
        ecoEnergyEfficiency: "#",
        ecoWasteManagement: "#",
        ecoLifeCycle: "#",
        areaAeo: "#",
        areaTableAeo: "#",
        areaSurfaces: '#',
        tracingContract: "#",
        tracingCost: '#',
        tracingTerm: '#',
        tracingBudgets: '#',
        tracingCurrentPhase: '#',
        tracingScan: "https://aec.cintoo.com/243906351F271A1F3438",
        buildingMemoryQuality: '#',
        buildingCFO: '#',
        buildingWorkRecords: '#',
        buildingCadastralReference: '#',
        buildingLicense: '#',
        buildingASBuilt: '#',
        buildingWellnessCertificate: '#',
        buildingPECOC: '#',
        buildingLegalizations: '#',
        buildingCompaniesInvolved: '#',
        buildingBuildingBook: '#',
        buildingPlanning: '#',
        buildingFlats: '#',
    },
    {
      id: "37",
      name: "R. Arellano",
      address: "Ramírez de Arellano, 15",
      img_path: "madrid/Ramirez_Arellano_15.jpg",
      img: Ramirez_Arellano_15,
      bream1: "Bream Parte I: Good",
      bream2: "Bream Parte II: Very Good",
      bream3: "Leed Gold",
      hidden: "",
      hidden2: "",
      imgbream1: breeam1,
      imgbream2: breeam2,
      imgbream3: gold,
      position: { lat: 40.44893987190027, lng: -3.654226051581806 },
        bimCoordination: '#',
        bimArchitecture: '#',
        bimInstallations: '#',
        bimMepMechanics: '#',
        bimMepPlumbing: '#',
        bimMepElectricity: '#',
        bimMepPci: '#',
        bimMepBms: '#',
        bimImplantations: '#',
        bimCad: '#',
        docWorkRecords: '#',
        docWorkCertificate: '#',
        docMaintenanceCertificate: '#',
        docGeotechnicalControl: '#',
        docControlInstallations: '#',
        docActivitiesCoordination: '#',
        docCorrectives: '#',
        docGeneral: '#',
        docAdministrativeLegal: '#',
        docRSC: '#',
        docPrevious: '#',
        docRisksEvaluation: '#',
        docISOs: '#',
        docImplementations: '#',
        docOCTReports: '#',
        docWorkReports:'#',
        docTechnicalReports: '#',
        docBuildingBook:'#',
        docLicenses: '#',
        docBiddingWorkContracts: '#',
        docSelfprotectionManuals: '#',
        docProjects:'#',
        docProcessingLegalization: '#',
        docFlats: '#',
        commercialFlats: '#',
        commercialSurfaces: '#',
        commercialProjectReport: '#',
        commercialCurrentPhase: '#',
        commercialRenders: '#',
        commercialAR: '#',
        stockStocks: '#',
        ecoCarbonFootprint: "#",
        ecoCertificate: "#",
        ecoProvisioning: "#",
        ecoEnergyEfficiency: "#",
        ecoWasteManagement: "#",
        ecoLifeCycle: "#",
        areaAeo: "#",
        areaTableAeo: "#",
        areaSurfaces: '#',
        tracingContract: "#",
        tracingCost: '#',
        tracingTerm: '#',
        tracingBudgets: '#',
        tracingCurrentPhase: '#',
        tracingScan: "#",
        buildingMemoryQuality: '#',
        buildingCFO: '#',
        buildingWorkRecords: '#',
        buildingCadastralReference: '#',
        buildingLicense: '#',
        buildingASBuilt: '#',
        buildingWellnessCertificate: '#',
        buildingPECOC: '#',
        buildingLegalizations: '#',
        buildingCompaniesInvolved: '#',
        buildingBuildingBook: '#',
        buildingPlanning: '#',
        buildingFlats: '#',
    },
    {
      id: "38",
      name: "J. Valcárcel 24",
      address: "Josefa Valcárcel, 24",
      img_path: "madrid/Josefa_Valcarcel_24.jpg",
      img: Josefa_Valcarcel_24,
      bream1: "Bream Parte I: Good",
      bream2: "Bream Parte II: Very Good",
      bream3: "",
      hidden: "",
      hidden2: "invisible",
      imgbream1: breeam1,
      imgbream2: breeam2,
      imgbream3: platinum,
      position: { lat: 40.44883732584063, lng: -3.645554614396363 },
        bimCoordination: '#',
        bimArchitecture: '#',
        bimInstallations: '#',
        bimMepMechanics: '#',
        bimMepPlumbing: '#',
        bimMepElectricity: '#',
        bimMepPci: '#',
        bimMepBms: '#',
        bimImplantations: '#',
        bimCad: '#',
        docWorkRecords: '#',
        docWorkCertificate: '#',
        docMaintenanceCertificate: '#',
        docGeotechnicalControl: '#',
        docControlInstallations: '#',
        docActivitiesCoordination: '#',
        docCorrectives: '#',
        docGeneral: '#',
        docAdministrativeLegal: '#',
        docRSC: '#',
        docPrevious: '#',
        docRisksEvaluation: '#',
        docISOs: '#',
        docImplementations: '#',
        docOCTReports: '#',
        docWorkReports:'#',
        docTechnicalReports: '#',
        docBuildingBook:'#',
        docLicenses: '#',
        docBiddingWorkContracts: '#',
        docSelfprotectionManuals: '#',
        docProjects:'#',
        docProcessingLegalization: '#',
        docFlats: '#',
        commercialFlats: '#',
        commercialSurfaces: '#',
        commercialProjectReport: '#',
        commercialCurrentPhase: '#',
        commercialRenders: '#',
        commercialAR: '#',
        stockStocks: '#',
        ecoCarbonFootprint: "#",
        ecoCertificate: "#",
        ecoProvisioning: "#",
        ecoEnergyEfficiency: "#",
        ecoWasteManagement: "#",
        ecoLifeCycle: "#",
        areaAeo: "#",
        areaTableAeo: "#",
        areaSurfaces: '#',
        tracingContract: "#",
        tracingCost: '#',
        tracingTerm: '#',
        tracingBudgets: '#',
        tracingCurrentPhase: '#',
        tracingScan: "#",
        buildingMemoryQuality: '#',
        buildingCFO: '#',
        buildingWorkRecords: '#',
        buildingCadastralReference: '#',
        buildingLicense: '#',
        buildingASBuilt: '#',
        buildingWellnessCertificate: '#',
        buildingPECOC: '#',
        buildingLegalizations: '#',
        buildingCompaniesInvolved: '#',
        buildingBuildingBook: '#',
        buildingPlanning: '#',
        buildingFlats: '#',
    },
    {
      id: "39",
      name: "J. Valcárcel 40",
      address: "Josefa Valcárcel, 40 bis",
      img_path: "madrid/Josefa_Valcarcel_40.jpg",
      img: Josefa_Valcarcel_40,
      bream1: "Bream Parte I: Good",
      bream2: "Bream Parte II: Very Good",
      bream3: "Leed Platinum",
      hidden: "",
      hidden2: "",
      imgbream1: breeam1,
      imgbream2: breeam2,
      imgbream3: platinum,
      position: { lat: 40.4486303520685, lng: -3.641122159991289 },
        bimCoordination: '#',
        bimArchitecture: '#',
        bimInstallations: '#',
        bimMepMechanics: '#',
        bimMepPlumbing: '#',
        bimMepElectricity: '#',
        bimMepPci: '#',
        bimMepBms: '#',
        bimImplantations: '#',
        bimCad: '#',
        docWorkRecords: '#',
        docWorkCertificate: '#',
        docMaintenanceCertificate: '#',
        docGeotechnicalControl: '#',
        docControlInstallations: '#',
        docActivitiesCoordination: '#',
        docCorrectives: '#',
        docGeneral: '#',
        docAdministrativeLegal: '#',
        docRSC: '#',
        docPrevious: '#',
        docRisksEvaluation: '#',
        docISOs: '#',
        docImplementations: '#',
        docOCTReports: '#',
        docWorkReports:'#',
        docTechnicalReports: '#',
        docBuildingBook:'#',
        docLicenses: '#',
        docBiddingWorkContracts: '#',
        docSelfprotectionManuals: '#',
        docProjects:'#',
        docProcessingLegalization: '#',
        docFlats: '#',
        commercialFlats: '#',
        commercialSurfaces: '#',
        commercialProjectReport: '#',
        commercialCurrentPhase: '#',
        commercialRenders: '#',
        commercialAR: '#',
        stockStocks: '#',
        ecoCarbonFootprint: "#",
        ecoCertificate: "#",
        ecoProvisioning: "#",
        ecoEnergyEfficiency: "#",
        ecoWasteManagement: "#",
        ecoLifeCycle: "#",
        areaAeo: "#",
        areaTableAeo: "#",
        areaSurfaces: '#',
        tracingContract: "#",
        tracingCost: '#',
        tracingTerm: '#',
        tracingBudgets: '#',
        tracingCurrentPhase: '#',
        tracingScan: "#",
        buildingMemoryQuality: '#',
        buildingCFO: '#',
        buildingWorkRecords: '#',
        buildingCadastralReference: '#',
        buildingLicense: '#',
        buildingASBuilt: '#',
        buildingWellnessCertificate: '#',
        buildingPECOC: '#',
        buildingLegalizations: '#',
        buildingCompaniesInvolved: '#',
        buildingBuildingBook: '#',
        buildingPlanning: '#',
        buildingFlats: '#',
    },
    {
      id: "40",
      name: "JILT7",
      address: "Juan I.Luca de Tena, 7",
      img_path: "madrid/JILT7.jpg",
      img: JILT7,
      bream1: "Leed Platinum",
      bream2: "",
      bream3: "",
      hidden: "invisible",
      hidden2: "invisible",
      imgbream1: platinum,
      position: { lat: 40.44801131007211, lng: -3.6348441518489825 },
        bimCoordination: '#',
        bimArchitecture: '#',
        bimInstallations: '#',
        bimMepMechanics: '#',
        bimMepPlumbing: '#',
        bimMepElectricity: '#',
        bimMepPci: '#',
        bimMepBms: '#',
        bimImplantations: '#',
        bimCad: '#',
        docWorkRecords: '#',
        docWorkCertificate: '#',
        docMaintenanceCertificate: '#',
        docGeotechnicalControl: '#',
        docControlInstallations: '#',
        docActivitiesCoordination: '#',
        docCorrectives: '#',
        docGeneral: '#',
        docAdministrativeLegal: '#',
        docRSC: '#',
        docPrevious: '#',
        docRisksEvaluation: '#',
        docISOs: '#',
        docImplementations: '#',
        docOCTReports: '#',
        docWorkReports:'#',
        docTechnicalReports: '#',
        docBuildingBook:'#',
        docLicenses: '#',
        docBiddingWorkContracts: '#',
        docSelfprotectionManuals: '#',
        docProjects:'#',
        docProcessingLegalization: '#',
        docFlats: '#',
        commercialFlats: '#',
        commercialSurfaces: '#',
        commercialProjectReport: '#',
        commercialCurrentPhase: '#',
        commercialRenders: '#',
        commercialAR: '#',
        stockStocks: '#',
        ecoCarbonFootprint: "#",
        ecoCertificate: "#",
        ecoProvisioning: "#",
        ecoEnergyEfficiency: "#",
        ecoWasteManagement: "#",
        ecoLifeCycle: "#",
        areaAeo: "#",
        areaTableAeo: "#",
        areaSurfaces: '#',
        tracingContract: "#",
        tracingCost: '#',
        tracingTerm: '#',
        tracingBudgets: '#',
        tracingCurrentPhase: '#',
        tracingScan: "#",
        buildingMemoryQuality: '#',
        buildingCFO: '#',
        buildingWorkRecords: '#',
        buildingCadastralReference: '#',
        buildingLicense: '#',
        buildingASBuilt: '#',
        buildingWellnessCertificate: '#',
        buildingPECOC: '#',
        buildingLegalizations: '#',
        buildingCompaniesInvolved: '#',
        buildingBuildingBook: '#',
        buildingPlanning: '#',
        buildingFlats: '#',
    },
    {
      id: "41",
      name: "Alcalá 506",
      address: "Calle Alcalá, 506",
      img_path: "madrid/alcala_506.jpg",
      img: alcala_506,
      bream1: "Bream Parte I: Good",
      bream2: "Bream Parte II: Good",
      bream3: "",
      hidden: "",
      hidden2: "invisible",
      imgbream1: breeam1,
      imgbream2: breeam2,
      position: { lat: 40.440737962545796, lng: -3.627155680223678 },
        bimCoordination: '#',
        bimArchitecture: '#',
        bimInstallations: '#',
        bimMepMechanics: '#',
        bimMepPlumbing: '#',
        bimMepElectricity: '#',
        bimMepPci: '#',
        bimMepBms: '#',
        bimImplantations: '#',
        bimCad: '#',
        docWorkRecords: '#',
        docWorkCertificate: '#',
        docMaintenanceCertificate: '#',
        docGeotechnicalControl: '#',
        docControlInstallations: '#',
        docActivitiesCoordination: '#',
        docCorrectives: '#',
        docGeneral: '#',
        docAdministrativeLegal: '#',
        docRSC: '#',
        docPrevious: '#',
        docRisksEvaluation: '#',
        docISOs: '#',
        docImplementations: '#',
        docOCTReports: '#',
        docWorkReports:'#',
        docTechnicalReports: '#',
        docBuildingBook:'#',
        docLicenses: '#',
        docBiddingWorkContracts: '#',
        docSelfprotectionManuals: '#',
        docProjects:'#',
        docProcessingLegalization: '#',
        docFlats: '#',
        commercialFlats: '#',
        commercialSurfaces: '#',
        commercialProjectReport: '#',
        commercialCurrentPhase: '#',
        commercialRenders: '#',
        commercialAR: '#',
        stockStocks: '#',
        ecoCarbonFootprint: "#",
        ecoCertificate: "#",
        ecoProvisioning: "#",
        ecoEnergyEfficiency: "#",
        ecoWasteManagement: "#",
        ecoLifeCycle: "#",
        areaAeo: "#",
        areaTableAeo: "#",
        areaSurfaces: '#',
        tracingContract: "#",
        tracingCost: '#',
        tracingTerm: '#',
        tracingBudgets: '#',
        tracingCurrentPhase: '#',
        tracingScan: "#",
        buildingMemoryQuality: '#',
        buildingCFO: '#',
        buildingWorkRecords: '#',
        buildingCadastralReference: '#',
        buildingLicense: '#',
        buildingASBuilt: '#',
        buildingWellnessCertificate: '#',
        buildingPECOC: '#',
        buildingLegalizations: '#',
        buildingCompaniesInvolved: '#',
        buildingBuildingBook: '#',
        buildingPlanning: '#',
        buildingFlats: '#',
    },
]
export default madridData
